import http from './http'

const asset = {
  findAgent,
  getAgents,
  getAgentPermission,
  createAgent,
  patchAgent,
  updateAgentPermission,
  getAgentBalances,

  getBots,
  findBot,
  patchBot,
  patchBotActions,
  createBot,
  transferBot,

  getExchanges,

  getMarkets,
}
export default asset

export async function findAgent (id) {
  return await http.get(`agents/${id}`)
}

export async function patchAgent (id, body) {
  return await http.patch(`agents/${id}`, { body })
}

export async function getAgents () {
  return await http.get('agents')
}

export async function getAgentPermission (id) {
  return await http.get(`agents/${id}/permissions`)
}

export async function updateAgentPermission (id, body) {
  return await http.patch(`agents/${id}/permission`, { body })
}

export async function getAgentBalances (id) {
  return await http.get('balances', { query: { agent_id: id } })
}

export async function createAgent (body) {
  return await http.post('agents', { body })
}

export async function getBots () {
  return await http.get('bots')
}

export async function findBot (id) {
  return await http.get(`bots/${id}`)
}

export async function patchBot (id, body) {
  return await http.patch(`bots/${id}`, { body })
}

export async function patchBotActions (id, actions) {
  return await http.patch(`bots/${id}`, { body: actions })
}

export async function createBot (body) {
  return await http.post('bots', { body: body })
}

export async function transferBot (body) {
  return await http.post('bots/transfer', { body })
}

export async function getExchanges () {
  return await http.get('exchanges')
}

export async function getMarkets () {
  return await http.get('markets')
}
