import React from 'react'
import { SignUpForm } from './SignUpForm'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Container } from '@mui/material'
import { SIGNIN } from '../../path'

export function SignUp () {
  const navigate = useNavigate()

  return (
    <Container component="main" maxWidth="xs">
      <SignUpForm />
      <Box>
        <Typography variant='body2' sx={{ ':hover': { cursor: 'pointer', textDecoration: 'underline' } }} onClick={() => navigate(`/${SIGNIN}`)}>
          Back to Sign in
        </Typography>
      </Box>
    </Container>
  )
}
