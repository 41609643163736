import React, { createContext, useEffect, useMemo, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { useContext } from './context'

const ThemeModeContext = createContext()

export const useThemeModeContext =
  () => useContext('ThemeModeContext', ThemeModeContext)

export function ThemeModeContextProvider ({ children }) {
  const [mode, setMode] = useState('dark')
  const theme = useMemo(() => createTheme(
    {
      palette: {
        mode,
        up: { main: '#0ecb81', light: '#61ffb1', dark: '#009954' },
        down: { main: '#f6465d', light: '#ff7c8a', dark: '#bd0034' },
      },
    }), [mode],
  )

  useEffect(() => {
    // TODO load mode from local-storage
  })

  return (
    <ThemeModeContext.Provider value={{ theme, mode, setMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </ThemeModeContext.Provider>
  )
}
