const ACCESS_KEY = 'access-key'
const DEVICE_KEY = 'device-key'

const session = {
  getAccessKey,
  setAccessKey,
  removeAccessKey,

  getDeviceKey,
  setDeviceKey,
  removeDeviceKey,
}
export default session

export function getAccessKey () {
  return JSON.parse(window.sessionStorage.getItem(ACCESS_KEY))
}

export function setAccessKey (key) {
  window.sessionStorage.setItem(ACCESS_KEY, JSON.stringify(key))
}

export function removeAccessKey () {
  window.sessionStorage.removeItem(ACCESS_KEY)
}

export function getDeviceKey () {
  return JSON.parse(window.localStorage.getItem(DEVICE_KEY))
}

export function setDeviceKey (key) {
  window.localStorage.setItem(DEVICE_KEY, JSON.stringify(key))
}

export function removeDeviceKey () {
  window.localStorage.removeItem(DEVICE_KEY)
}
