import React, { useMemo, useReducer, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext, useMessagesContext } from '../../context'
import { auth as authService } from '../../service'
import { DASHBOARD } from '../../path'
import { SignInForm } from './SignInForm'
import { EnableTwoFa } from './EnableTwoFa'

export function SignIn () {
  const { auth, setAuth } = useAuthContext()
  const { pushMessages } = useMessagesContext()
  const navigate = useNavigate()
  const location = useLocation()
  const from = useMemo(
    () => location.state?.from?.pathname ?? DASHBOARD,
    [location],
  )

  const [values, setValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { email: '', password: '', otpCode: '' },
  )
  const [twoFaValues, setTwoFaValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { secret: '', uri: '' },
  )
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  // status: sign-in, enable-2fa
  const [status, setStatus] = useState('sign-in')
  const [stepOtp, setStepOtp] = useState(false)

  if (auth.user) return <Navigate to={from} replace={true} />

  const enable2FA = async () => {
    setStatus('enable-2fa')
    setError(null)
    try {
      const res = await authService.enable2FA(values)
      setTwoFaValues(res.body)
    } catch (err) {
      setError(err)
    }
  }

  const onSubmit = async (e) => {
    if (values.email === '' || values.password === '') return
    e.preventDefault()
    setLoading(true)
    try {
      await authService.signIn(setAuth, values)
      navigate(from, { replace: true })
    } catch (err) {
      setLoading(false)
      if (err.name === 'BadCredentialError') {
        setError('Invalid login')
      } else if (err.name === 'RequireTwoFactorAuthenticationError') {
        enable2FA()
      } else if (err.name === 'NoTwoFactorAuthenticationError') {
        setStepOtp(true)
      } else {
        setError(err.message)
      }
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target
    setValues({ [name]: value })
  }

  const onConfirm = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await authService.confirm2FA(values)
      pushMessages({ severity: 'success', text: 'Success! Please login again.' })
      setStatus('sign-in')
      setValues({ email: '', password: '', otpCode: '' })
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  if (status === 'enable-2fa') {
    return <EnableTwoFa
      values={values}
      twoFaValues={twoFaValues}
      onConfirm={onConfirm}
      onChange={onChange}
      loading={loading}
      error={error}
    />
  }

  return <SignInForm
    values={values}
    onSubmit={onSubmit}
    onChange={onChange}
    loading={loading}
    error={error}
    stepOtp={stepOtp}
  />
}
