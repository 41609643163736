import http from './http'

const user = {
  getMe,
}
export default user

export async function getMe () {
  return await http.get('me')
}
