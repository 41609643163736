import React, { useMemo, useCallback, useEffect, useState } from 'react'

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PercentIcon from '@mui/icons-material/Percent'
import ExposureIcon from '@mui/icons-material/Exposure'

import api, { useApi } from '../../api'
import { EditDialog } from './util'
import { getActionsError } from '../../util/errorHelper'
import { getRealTimeStatus } from '../../util/realTimeSatus'
import { allows } from '../../util/permission'

export function Actions (props) {
  const { agent, bot, setBot, ticker } = props
  const [fire] = useApi()

  if (!bot) return null
  return (
    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <Box sx={{ flex: '1' }}>
        <TakeProfit agent={agent} bot={bot} setBot={setBot} toggleAction={toggleAction} ticker={ticker} />
      </Box>
      <Box sx={{ flex: '1' }}>
        <StopLoss agent={agent} bot={bot} setBot={setBot} toggleAction={toggleAction} ticker={ticker} />
      </Box>
    </Box>
  )

  function toggleAction (key, enabled) {
    if (!bot) return

    fire(
      api
        .asset
        .patchBot(
          bot?.id,
          { [key]: { ...(bot?.[key] ?? {}), is_enabled: enabled } },
        )
        .then((res) => {
          setBot(res.body)
          return res
        }),
      true,
      true,
    )
  }
}

function TakeProfit ({ agent, bot, setBot, toggleAction, ticker }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <TakeProfitInfo agent={agent} bot={bot} toggleAction={toggleAction} ticker={ticker} />
      <TakeProfitEdit agent={agent} bot={bot} setBot={setBot} />
    </Box>
  )
}

function TakeProfitInfo ({ agent, bot, toggleAction, ticker }) {
  const clickable = useMemo(() => !!bot?.take_profit && allows(agent, 'trade'), [bot])
  const enabled = useMemo(() => bot?.take_profit?.is_enabled === true, [bot])

  return (
    <>
      <FormGroup>
        <FormControlLabel
          label="Take Profit"
          control={<Switch
            checked={enabled}
            disabled={!clickable}
            onChange={onClick}
            color="warning"
          />}
        />
      </FormGroup>
      <Box sx={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>{getRealTimeStatus(bot, ticker, 'take-profit')}</Box>
    </>
  )

  function onClick () {
    if (clickable) toggleAction('take_profit', !enabled)
  }
}

function TakeProfitEdit ({ agent, bot, setBot }) {
  const [values, setValues] = useState({})
  const [hasError, setHasError] = useState(false)
  const [protection, setProtection] = useState(true)
  const {
    enabled, setEnabled,
    method, setMethod,
    args, setArg,
  } = useActions({ key: 'take_profit', bot, values, setValues })
  const [fire] = useApi()
  const totalIsZero = bot.total === '0'

  const getError = useCallback(
    (field, value) => getActionsError(bot, 'take-profit', method, field, value, protection),
    [bot, method, protection],
  )

  const handleChange = (event) => {
    setProtection(event.target.checked)
  }

  return (
    <EditDialog
      disabled={!bot || !allows(agent, 'trade')}
      title={`Take-profit for bot ${bot?.id ?? ''}`}
      onClose={onClose}
      onSubmit={onSubmit}
      submittable={!hasError && !(protection && totalIsZero)}
    >
      <Box>
        <FormControlLabel
          control={<Switch
            checked={protection}
            onChange={handleChange} />}
          label="Enable Protection" />
      </Box>
      <Box>
        <EnableCheckbox
          label='take-profit'
          checked={enabled}
          setChecked={setEnabled}
        />
      </Box>
      <Box>
        <FormControl component="fieldset">
          <FormLabel component="legend">Method</FormLabel>
          <RadioGroup
            aria-label="method"
            value={method}
            name="radio-buttons-group"
            onChange={({ target: { value } }) => setMethod(value)}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="trigger price"
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="trigger price with retracement"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {method === '1' && <TakeProfitEditMethod1 args={args} setArg={setArg} getError={getError} setHasError={setHasError} />}
      {method === '2' && <TakeProfitEditMethod2 args={args} setArg={setArg} getError={getError} setHasError={setHasError} />}
      {protection && totalIsZero && <Box sx={{ color: 'red' }}>Cannot submit if total is 0.</Box>}
    </EditDialog>
  )

  function onClose (close) {
    close()
    setValues({})
  }

  function onSubmit (close) {
    if (!bot) return

    fire(
      api
        .asset
        .patchBot(
          bot?.id,
          { take_profit: { is_enabled: enabled, method, args } },
        )
        .then((res) => {
          setBot(res.body)
          close()
          return res
        })
        .finally(() => close(false)),
      true,
      true,
    )
  }
}

function TakeProfitEditMethod1 ({ args, setArg, getError, setHasError }) {
  const triggerPriceError = getError('trigger-price', args?.[0])
  const quantityError = getError('quantity', args?.[1])

  useEffect(() => {
    setHasError(triggerPriceError || quantityError)
  }, [triggerPriceError, quantityError, setHasError])

  return (
    <Box sx={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <NumberInput
        label="Trigger price"
        number={args?.[0] ?? ''}
        setNumber={n => setArg(0, n)}
        error={triggerPriceError}
      />
      <NumberInput
        label="Position ratio"
        number={args?.[1] ?? ''}
        setNumber={n => setArg(1, n)}
        isPosition
        error={quantityError}
      />
    </Box>
  )
}

function TakeProfitEditMethod2 ({ args, setArg, getError, setHasError }) {
  const triggerPriceError = getError('trigger-price', args?.[0])
  const retracementError = getError('retracement-price', args?.[1])
  const quantityError = getError('quantity', args?.[2])

  useEffect(() => {
    setHasError(triggerPriceError || retracementError || quantityError)
  }, [triggerPriceError, retracementError, quantityError, setHasError])

  return (
    <Box sx={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <NumberInput
        label="Trigger price"
        number={args?.[0] ?? ''}
        setNumber={n => setArg(0, n)}
        error={triggerPriceError}
      />
      <NumberInput
        label="Retracement price"
        number={args?.[1] ?? ''}
        setNumber={n => setArg(1, n)}
        error={retracementError}
      />
      <NumberInput
        label="Position ratio"
        number={args?.[2] ?? ''}
        setNumber={n => setArg(2, n)}
        isPosition
        error={quantityError}
      />
    </Box>
  )
}

function StopLoss ({ agent, bot, setBot, toggleAction, ticker }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <StopLossInfo agent={agent} bot={bot} toggleAction={toggleAction} ticker={ticker} />
      <StopLossEdit agent={agent} bot={bot} setBot={setBot} />
    </Box>
  )
}

function StopLossInfo ({ agent, bot, toggleAction, ticker }) {
  const clickable = useMemo(() => !!bot?.stop_loss && allows(agent, 'trade'), [bot])
  const enabled = useMemo(() => bot?.stop_loss?.is_enabled === true, [bot])

  return (
    <>
      <FormGroup>
        <FormControlLabel
          label="Stop Loss"
          control={<Switch
            checked={enabled}
            disabled={!clickable}
            onChange={onClick}
            color="warning"
          />}
        />
      </FormGroup>
      <Box sx={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>{getRealTimeStatus(bot, ticker, 'stop-loss')}</Box>
    </>
  )

  function onClick () {
    if (clickable) toggleAction('stop_loss', !enabled)
  }
}

function StopLossEdit ({ agent, bot, setBot }) {
  const [values, setValues] = useState({})
  const [hasError, setHasError] = useState(false)
  const [protection, setProtection] = useState(true)
  const {
    enabled, setEnabled,
    method,
    args, setArg,
  } = useActions({ key: 'stop_loss', bot, values, setValues })
  const [fire] = useApi()
  const totalIsZero = bot.total === '0'

  const getError = useCallback(
    (field, value) => getActionsError(bot, 'stop-loss', method, field, value, protection),
    [bot, method, protection],
  )

  const handleChange = (event) => {
    setProtection(event.target.checked)
  }

  return (
    <EditDialog
      disabled={!bot || !allows(agent, 'trade')}
      title={`Stop-loss for bot ${bot?.id ?? ''}`}
      onClose={onClose}
      onSubmit={onSubmit}
      submittable={!hasError && !(protection && totalIsZero)}
    >
      <Box>
        <FormControlLabel
          control={<Switch
            checked={protection}
            onChange={handleChange} />}
          label="Enable Protection" />
      </Box>
      <Box>
        <EnableCheckbox
          label='stop-loss'
          checked={enabled}
          setChecked={setEnabled}
        />
      </Box>
      {method === '1' && <StopLossEditMethod1 args={args} setArg={setArg} getError={getError} setHasError={setHasError} />}
      {protection && totalIsZero && <Box sx={{ color: 'red' }}>Cannot submit if total is 0.</Box>}
    </EditDialog>
  )

  function onClose (close) {
    close()
    setValues({})
  }

  function onSubmit (close) {
    if (!bot) return

    fire(
      api
        .asset
        .patchBot(
          bot?.id,
          { stop_loss: { is_enabled: enabled, method, args } },
        )
        .then((res) => {
          setBot(res.body)
          close()
          return res
        })
        .finally(() => close(false)),
      true,
      true,
    )
  }
}

function StopLossEditMethod1 ({ args, setArg, getError, setHasError }) {
  const triggerPriceError = getError('trigger-price', args?.[0])

  useEffect(() => {
    setHasError(triggerPriceError)
  }, [triggerPriceError, setHasError])

  return (
    <Box sx={{ marginTop: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <NumberInput
        label="Trigger price"
        number={args?.[0] ?? ''}
        setNumber={n => setArg(0, n)}
        error={triggerPriceError}
      />
    </Box>
  )
}

function NumberInput ({ label, number, setNumber, isPosition, error }) {
  return (
    <Box sx={{ display: 'flex', gap: '15px', alignItems: 'flex-start', height: '70px' }}>
      <ToggleButtonGroup
        value={number?.[0]}
        exclusive
        aria-label="text alignment"
        size="small"
        onChange={(e, v) => {
          if (v !== null) setNumber(`${v}${number?.substring(1) ?? ''}`)
        }}
      >
        <ToggleButton value="$" disabled={isPosition}><Tooltip title="absolute"><AttachMoneyIcon /></Tooltip></ToggleButton>
        <ToggleButton value="+" disabled={isPosition}><Tooltip title="offset"><ExposureIcon /></Tooltip></ToggleButton>
        <ToggleButton value="%"><Tooltip title="percentage"><PercentIcon /></Tooltip></ToggleButton>
      </ToggleButtonGroup>
      <TextField
        label={label}
        type="string"
        size="small"
        error={error !== null}
        value={number?.substring(1) ?? ''}
        onChange={({ target: { value } }) => setNumber(`${number?.[0] ?? (isPosition ? '%' : '$')}${value}`)}
        helperText={error}
      />
    </Box>
  )
}

function EnableCheckbox ({ label, checked, setChecked }) {
  return (
    <FormControlLabel
      control={<Checkbox
        checked={checked}
        onChange={({ target: { checked } }) => setChecked(checked)}
      />}
      label={`Enable ${label}`}
    />
  )
}

function useActions ({ bot, key, values, setValues }) {
  const enabled = useMemo(
    () => (values?.is_enabled ?? bot?.[key]?.is_enabled) === true,
    [bot, key, values],
  )
  const setEnabled = useCallback(
    enabled => setValues(prev => ({ ...prev, is_enabled: enabled })),
    [setValues],
  )
  const method = useMemo(
    () => values?.method ?? bot?.[key]?.method ?? '1',
    [bot, key, values],
  )
  const setMethod = useCallback(
    method => setValues(prev => ({ ...prev, method, args: [] })),
    [setValues],
  )
  const args = useMemo(
    () => {
      const value = values?.args
      const fallback = bot?.[key]?.args
      if (values?.method && values?.method !== bot?.[key]?.method) {
        return value ?? []
      }
      return merge(value, fallback)

      function merge (a, b) {
        if (!a) return b
        if (!b) return a
        const merged = []
        const length = Math.max(a.length, b.length)
        for (let i = 0; i < length; ++i) merged[i] = a[i] ?? b[i]
        return merged
      }
    },
    [bot, key, values],
  )
  const setArgs = useCallback(
    args => setValues(prev => ({ ...prev, args })),
    [setValues],
  )
  const setArg = useCallback(
    (i, arg) => setValues((prev) => {
      return {
        ...prev,
        args: merge(prev?.args ?? [], i, arg),
      }

      function merge (args, index, arg) {
        if (args.length > index + 1) {
          return [...args.slice(0, index), arg, ...args.slice(index + 1)]
        }
        const merged = []
        for (let i = 0; i < index; ++i) merged[i] = args[i]
        merged[index] = arg
        return merged
      }
    }),
    [setValues],
  )
  return {
    enabled,
    setEnabled,
    method,
    setMethod,
    args,
    setArg,
    setArgs,
  }
}
