import React, { useCallback, useMemo, useReducer, useState } from 'react'
import BigNumber from 'bignumber.js'
import format from 'date-fns/format'
import randomColor from 'randomcolor'
import {
  Box,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Pagination,
  Slider,
} from '@mui/material'
import { useThemeModeContext } from '../../context/theme-mode'
import api, { useApi } from '../../api'
import { toFixed } from '../../util'
import { UpDown, getPnLDir, parseMarket } from './util'
import { allows } from '../../util/permission'

export function PlaceOrder ({ agent, bot, setOpenOrder, ticker, balances }) {
  const [update, updating] = useApi()
  const [values, dispatch] = useReducer(
    (prev, now) => ({ ...prev, ...now }),
    { price: '', quantity: '', total: '' },
  )
  const buyingPower = useMemo(() => {
    if (!bot) return '0'
    const balance = balances.find((v) => v.coin === parseMarket(bot.market)[1])
    if (balance === undefined) return '0'
    return balance.free
  }, [balances, bot])
  const lastPrice = useMemo(
    () => {
      const a = new BigNumber(ticker?.a)
      const b = new BigNumber(ticker?.b)
      return a.isNaN() || b.isNaN() ? '' : a.plus(b).div(2).toFixed()
    },
    [ticker],
  )
  const setPrice = useCallback(
    price => dispatch({ price }),
    [dispatch],
  )
  const quantity = useMemo(
    () => {
      const { price, quantity, total } = values
      if (quantity !== '') return quantity
      if (total !== '') {
        const t = new BigNumber(total)
        const p = new BigNumber(price === '' ? lastPrice : price)
        if (!t.isNaN() && !p.isNaN()) return t.div(p).toFixed(8)
      }
      return ''
    },
    [lastPrice, values],
  )
  const total = useMemo(
    () => {
      const { price, quantity, total } = values
      if (total !== '') return total
      if (quantity !== '') {
        const q = new BigNumber(quantity)
        const p = new BigNumber(price === '' ? lastPrice : price)
        if (!q.isNaN() && !p.isNaN()) return q.times(p).toFixed()
      }
      return ''
    },
    [lastPrice, values],
  )
  const ratio = useMemo(() => buyingPower === '0' ? 0 : new BigNumber(total).div(buyingPower).times(100).toNumber(), [buyingPower, total])
  const setRatio = useCallback(
    (e, ratio) => dispatch({ total: new BigNumber(buyingPower).times(ratio / 100).toFixed(), quantity: '' })
    , [dispatch, buyingPower])
  const setQuantity = useCallback(
    quantity => dispatch({ quantity, total: '' }),
    [dispatch],
  )
  const setTotal = useCallback(
    total => dispatch({ total, quantity: '' }),
    [dispatch],
  )
  const priceError = useMemo(() => check(values.price), [values])
  const quantityError = useMemo(() => check(values.quantity), [values])
  const totalError = useMemo(() => check(values.total), [values])
  const disabled = useMemo(
    () => updating ||
      !bot ||
      priceError ||
      quantityError ||
      totalError ||
      (values.quantity === '' && values.total === '') ||
      !allows(agent, 'trade'),
    [bot, updating, priceError, quantityError, totalError, values],
  )

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
      height: '70px',
    }}>
      <Box sx={{ width: 1 / 6 }}>
        <NumberInput
          label="Price"
          value={values.price}
          error={priceError}
          setValue={setPrice}
          disabled={!allows(agent, 'trade')}
        />
      </Box>
      <Box sx={{ width: 1 / 6 }}>
        <NumberInput
          label="Quantity"
          value={quantity}
          error={quantityError}
          setValue={setQuantity}
          disabled={!allows(agent, 'trade')}
        />
      </Box>
      <Box sx={{ width: 1 / 6 }}>
        <NumberInput
          label="Total"
          value={total}
          error={totalError}
          setValue={setTotal}
          disabled={!allows(agent, 'trade')}
        />
      </Box>
      <Box sx={{ flex: 1, marginLeft: '15px', marginRight: '20px' }}>
        <Slider step={5} marks={[
          { value: 0, label: '0%' },
          { value: 25, label: '25%' },
          { value: 50, label: '50%' },
          { value: 75, label: '75%' },
          { value: 100, label: '100%' }]}
          value={ratio}
          onChange={setRatio}
          valueLabelDisplay="auto"
        />
      </Box>
      <Button
        sx={{ marginTop: '8px' }}
        color='up'
        disabled={disabled}
        variant="contained"
        onClick={() => submit('bid')}
      >Buy</Button>
      <Button
        sx={{ marginTop: '8px' }}
        color='down'
        disabled={disabled}
        variant="contained"
        onClick={() => submit('ask')}
      >Sell</Button>
    </Box>
  )

  function check (v) {
    if (v !== '') {
      const n = new BigNumber(v)
      if (n.isNaN() || n.lte(0)) return 'invalid number'
    }
    return null
  }

  function submit (side) {
    update(
      api
        .order
        .createOrder(
          bot.id,
          {
            price: values.price === '' ? undefined : values.price,
            quantity: sideize(side, values.quantity),
            total: sideize(side, values.total),
          },
        )
        .then(res => {
          setOpenOrder(res.body)
          return res
        }),
      true,
      true,
    )
  }

  function sideize (side, v) {
    const n = new BigNumber(v)
    if (n.isNaN()) return undefined
    return (side === 'ask' ? n.negated() : n).toFixed()
  }
}

function NumberInput ({ label, value, error, setValue, disabled }) {
  return (
    <TextField
      type="number"
      label={label}
      size="small"
      margin="dense"
      fullWidth
      value={value}
      error={!!error}
      onChange={({ target: { value } }) => setValue(value)}
      helperText={error}
      disabled={disabled}
    />
  )
}

const NUM_PER_PAGE = 20
export function Orders ({ orders, allOrdersCount, loadMore, filter, setFilter }) {
  const [page, setPage] = useState(1)

  const handlePage = (e, v) => {
    if (allOrdersCount <= v * NUM_PER_PAGE) loadMore(allOrdersCount)
    else if (orders.length < v * NUM_PER_PAGE) loadMore(v * NUM_PER_PAGE)
    setPage(v)
  }

  const handleFilter = (e, v) => {
    setFilter(v)
    setPage(1)
  }

  return (
    <TableContainer component={Paper} sx={{ my: 1, ml: { lg: 1 } }} >
      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
        <ToggleButtonGroup
          color="primary"
          value={filter}
          exclusive
          onChange={handleFilter}
          size="small"
        >
          <ToggleButton value={-1}>All</ToggleButton>
          <ToggleButton value={1}>Last Day</ToggleButton>
          <ToggleButton value={7}>Last Week</ToggleButton>
          <ToggleButton value={30}>Last Month</ToggleButton>
        </ToggleButtonGroup>
        <Pagination count={Math.ceil(allOrdersCount / NUM_PER_PAGE)} page={page} onChange={handlePage} />
      </Box>
      <Table size="small" aria-label="a dense table">
        <caption>Orders</caption>
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell align="right">
              <Box>Side</Box>
              <Box>Status</Box>
            </TableCell>
            <TableCell align="right">
              <Box>Price</Box>
              <Box>Avg.&nbsp;Price</Box>
            </TableCell>
            <TableCell align="right">
              <Box>Quantity</Box>
              <Box>Filled</Box>
            </TableCell>
            <TableCell align="right">
              <Box>Total</Box>
              <Box>Cost</Box>
            </TableCell>
            <TableCell align="right">Fee</TableCell>
            <TableCell align="right">P&L</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.length === 0
            ? <TableRow><TableCell colSpan={11}>No Data</TableCell></TableRow>
            : orders
              .slice((page - 1) * NUM_PER_PAGE, page * NUM_PER_PAGE)
              .map(order => <Order key={order.id} order={order} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function Order ({ order }) {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">{toDate(order.created_at)}</TableCell>
      <TableCell align="right">
        <Box><UpDown dir={order.side === 'bid' ? '+' : '-'}>{order.side}</UpDown></Box>
        <Box>{order.status}</Box>
      </TableCell>
      <TableCell align="right">
        <Box>{order.price ?? '-'}</Box>
        <Box>{toFixed(order.average, 4)}</Box>
      </TableCell>
      <TableCell align="right">
        <Box>{order.total ? '-' : abs(order.quantity)}</Box>
        <Box>{abs(order.filled)}</Box>
      </TableCell>
      <TableCell align="right">
        <Box>{toFixed(abs(order.total)) ?? '-'}</Box>
        <Box>{toFixed(abs(order.cost))}</Box>
      </TableCell>
      <TableCell align="right">{toFixed(order.commission_fee)}</TableCell>
      <TableCell align="right">
        <UpDown dir={getPnLDir(order.pnl)}>{toFixed(order.pnl)}</UpDown>
      </TableCell>
    </TableRow>
  )
}

export function OpenOrders ({ agents, bots, orders }) {
  if (orders.length === 0) return null
  return (
    <TableContainer component={Paper} sx={{ my: 1, ml: { lg: 1 } }}>
      <Table size="small" aria-label="a dense table">
        <caption>Open Orders</caption>
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell>Agent</TableCell>
            <TableCell>Bot</TableCell>
            <TableCell>Side</TableCell>
            <TableCell align="right">
              <Box>Price</Box>
              <Box>Avg.&nbsp;Price</Box>
            </TableCell>
            <TableCell align="right">
              <Box>Quantity</Box>
              <Box>Filled</Box>
            </TableCell>
            <TableCell align="right">
              <Box>Total</Box>
              <Box>Cost</Box>
            </TableCell>
            <TableCell align="right">Fee</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders
            .map(order => {
              const bot = bots.find((bot) => order.bot_id === bot.id)
              const agent = agents.find((agent) => bot?.agent_id === agent.id)
              return (<OpenOrder
                key={order.id}
                order={order}
                bot={bot}
                agent={agent}
              />)
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function OpenOrder ({ order, bot, agent }) {
  const [fire, updating] = useApi()
  const { mode } = useThemeModeContext()
  const color = randomColor({ seed: bot?.id ?? 'xxx', luminosity: mode })

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">{toDate(order.created_at)}</TableCell>
      <TableCell sx={{ background: color }}>
        <Box>{order.exchange?.toUpperCase()}</Box>
        <Box>{agent?.label}</Box>
      </TableCell>
      <TableCell sx={{ background: color }}>
        <Box>{order.market?.toUpperCase()}</Box>
        <Box>{bot?.label}</Box>
      </TableCell>
      <TableCell>
        <UpDown dir={order.side === 'bid' ? '+' : '-'}>{order.side}</UpDown>
      </TableCell>
      <TableCell align="right">
        <Box>{order.price ?? '-'}</Box>
        <Box>{toFixed(order.average, 4)}</Box>
      </TableCell>
      <TableCell align="right">
        <Box>{order.total ? '-' : abs(order.quantity)}</Box>
        <Box>{abs(order.filled)}</Box>
      </TableCell>
      <TableCell align="right">
        <Box>{toFixed(abs(order.total)) ?? '-'}</Box>
        <Box>{toFixed(abs(order.cost))}</Box>
      </TableCell>
      <TableCell align="right">{toFixed(order.commission_fee)}</TableCell>
      <TableCell>
        <Button disabled={updating} onClick={() => submit(order.id)} >Cancel</Button>
      </TableCell>
    </TableRow>
  )

  function submit (id) {
    fire(api.order.cancelOrder(id), true, true)
  }
}

function toDate (t) {
  return format(new Date(t), 'yyyy/MM/dd HH:mm:ss')
}

function abs (v) {
  const n = new BigNumber(v)
  return n.isNaN() ? null : n.abs().toFixed()
}
