import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { asset } from '../api'
import { useMessagesContext } from '../context'

export function Main () {
  const { pushMessages } = useMessagesContext()
  const [agents, setAgents] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    asset.getAgents()
      .then((res) => setAgents(res.body))
      .catch((err) => pushMessages({ severity: 'error', text: err.message }))
  }, [])

  const onAgentClick = (id) => () => navigate(id)

  if (!agents) return <div>Loading...</div>
  else if (agents.length === 0) return <div>No Agents</div>

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>exchange</TableCell>
            <TableCell>label</TableCell>
            <TableCell>sub-account</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agents.map((agent) =>
            <TableRow hover key={agent.id} sx={{ cursor: 'pointer' }} onClick={onAgentClick(agent.id)}>
              <TableCell>{agent.exchange}</TableCell>
              <TableCell>{agent.label}</TableCell>
              <TableCell>{agent.subaccount}</TableCell>
            </TableRow>,
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
