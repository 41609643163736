import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { parseMarket } from './util'

export function Balances ({ balances }) {
  return (
    <ul>
      {balances
        .filter(({ free, locked }) => new BigNumber(free).plus(locked).gt(0))
        .map(({ id, coin, free, locked }) => (
          <li key={id}>{coin} {free} {locked}</li>
        ))}
    </ul>
  )
}

export function Quotes ({ bots, balances }) {
  const quotes = useMemo(
    () => {
      const set = new Set()
      bots.forEach(({ market }) => set.add(parseMarket(market)[1]))
      return balances.filter(({ coin }) => set.has(coin))
    },
    [balances, bots],
  )
  return (
    <TableContainer component={Paper} sx={{ my: 1 }}>
      <Table size="small" aria-label="a dense table">
        <caption>Buying Power</caption>
        <TableHead>
          <TableRow>
            <TableCell>Coin</TableCell>
            <TableCell align="right">Free</TableCell>
            <TableCell align="right">Locked</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotes
            .map(quote => <Quote key={quote.id} quote={quote} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function Quote ({ quote }) {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">{quote.coin}</TableCell>
      <TableCell align="right">{quote.free}</TableCell>
      <TableCell align="right">{quote.locked}</TableCell>
      <TableCell align="right">{quote.total}</TableCell>
    </TableRow>
  )
}
