import React, { useEffect, useReducer, useState } from 'react'
import { asset, report } from '../api'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'
import moment from 'moment'
import { startOfToday, startOfTomorrow, startOfWeek, startOfMonth } from 'date-fns'
import { useParams } from 'react-router-dom'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { useMessagesContext } from '../context'

const NUM_PER_PAGE = 20
export function Transactions () {
  const { pushMessages } = useMessagesContext()
  const { agentId } = useParams()
  const [agent, setAgent] = useState()
  const [transactions, setTransactions] = useState(null)
  const [allTxCount, setAllTxCount] = useState(0)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [coinList, setCoinList] = useState(new Set())
  const [period, setPeriod] = useState('')

  const [params, dispatch] = useReducer(
    (prev, now) => ({ ...prev, ...now }),
    { begin: Date.now() - 90 * 24 * 60 * 60 * 1000, end: Date.now(), order: 'desc' },
  )

  useEffect(() => {
    if (agentId) {
      setTransactions(null)
      asset.getMarkets().then(res => {
        const tmpCoinList = new Set()
        res.body.forEach((v) => tmpCoinList.add(v.base).add(v.quote))
        setCoinList(tmpCoinList)
      })
      asset.findAgent(agentId)
        .then((res) => setAgent(res.body))
        .catch((err) => pushMessages({ severity: 'error', text: err.message }))
      report.getTransactions(agentId, params)
        .then((res) => {
          setCount(Math.ceil(res.pagination.count / NUM_PER_PAGE))
          setAllTxCount(res.pagination.count)
          setTransactions(res.body)
        })
        .catch((err) => pushMessages({ severity: 'error', text: err.message }))
    }
  }, [agentId])

  useEffect(() => {
    report.getTransactions(agentId, params)
      .then((res) => {
        setCount(Math.ceil(res.pagination.count / NUM_PER_PAGE))
        setAllTxCount(res.pagination.count)
        setTransactions(res.body)
      })
      .catch((err) => pushMessages({ severity: 'error', text: err.message }))
  }, [params])

  const handlePage = (e, v) => {
    if (allTxCount <= v * NUM_PER_PAGE) loadMore(allTxCount)
    else if (transactions.length < v * NUM_PER_PAGE) loadMore(v * NUM_PER_PAGE)
    setPage(v)
  }

  const loadMore = async (n) => {
    let currentLength = transactions.length
    let updatedTransactions = [...transactions]
    let updatedCount = allTxCount

    while (currentLength < n) {
      const res = await report.getTransactions(agentId, {
        offset: updatedTransactions.length,
        ...params,
      })
      updatedCount = res.pagination.count
      updatedTransactions = [...updatedTransactions, ...res.body]
      currentLength = updatedTransactions.length
    }
    setTransactions(updatedTransactions)
    setAllTxCount(updatedCount)
  }

  if (!agent) return <div>Loading...</div>

  return (
    <div>
      <p>Agent: {agent?.exchange.toUpperCase()} {agent?.label}</p>
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel>Coin</InputLabel>
          <Select
            value={params.coin ?? ''}
            label="Coin"
            onChange={(e) => {
              dispatch({ coin: e.target.value })
            }}
          >
            {[...coinList].map((v) => <MenuItem key={v} value={v}>{v.toUpperCase()}</MenuItem>)}
            <MenuItem value={undefined}>All</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Begin"
            value={params.begin}
            onChange={(v) => {
              dispatch({ begin: new Date(v).getTime() })
              setPeriod('')
            }}
            ampm={false}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="End"
            value={params.end}
            onChange={(v) => {
              dispatch({ end: new Date(v).getTime() })
              setPeriod('')
            }}
            ampm={false}
          />
        </LocalizationProvider>
        <ToggleButtonGroup
          color="primary"
          value={period}
          exclusive
          onChange={(e, v) => {
            let begin = Date.now() - 90 * 24 * 60 * 60 * 1000
            if (v === 'day') begin = startOfToday().getTime()
            else if (v === 'week') begin = startOfWeek(new Date()).getTime()
            else if (v === 'month') begin = startOfMonth(new Date()).getTime()
            dispatch({ begin, end: startOfTomorrow().getTime() })
            setPeriod(v)
          }}
        >
          <ToggleButton value={'day'}>本日</ToggleButton>
          <ToggleButton value={'week'}>本週</ToggleButton>
          <ToggleButton value={'month'}>本月</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {transactions && <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Datetime</TableCell>
              <TableCell>Coin</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Market</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.length === 0
              ? <TableRow><TableCell colSpan={6}>No Data</TableCell></TableRow>
              : transactions.slice((page - 1) * NUM_PER_PAGE, page * NUM_PER_PAGE).map(v => (
                <TableRow key={v.id}>
                  <TableCell>{moment(new Date(v.datetime)).format('YYYY/MM/DD HH:mm:ss')}</TableCell>
                  <TableCell>{v.coin.toUpperCase()}</TableCell>
                  <TableCell>{v.type}</TableCell>
                  <TableCell>{v.market?.toUpperCase()}</TableCell>
                  <TableCell>{v.amount}</TableCell>
                  <TableCell>{v.balance}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>}
      <Pagination count={count} page={page} onChange={handlePage} />
    </div>
  )
}
