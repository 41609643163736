import session from '../session'
import { auth as authApi, user } from '../api'

const auth = {
  signIn,
  signOut,
  enable2FA,
  confirm2FA,
  signUp,
}
export default auth

export async function signIn (save, credentials) {
  if (credentials) {
    const { email, password, otpCode } = credentials
    const { body: key } = await authApi.login({ email, password, otpCode: otpCode === '' ? undefined : otpCode })
    session.setDeviceKey(key)
  }
  try {
    if (session.getDeviceKey()) await authApi.refreshAccessKey()
    const key = session.getAccessKey()
    if (key) {
      const { body: me } = await user.getMe()
      save({ user: me, token: key.token })
    }
  } catch (err) {
    console.error(err)
    session.removeDeviceKey()
    await signOut(save)
  }
}

export async function signOut (save) {
  const key = session.getAccessKey()
  if (key) authApi.logout().catch(console.error)
  session.removeAccessKey()
  session.removeDeviceKey()
  save({ user: undefined, token: undefined })
}

export async function enable2FA (credentials) {
  return await authApi.enableOtp(credentials)
}

export async function confirm2FA (credentials) {
  return await authApi.confirmOtp(credentials)
}

export async function signUp (email, password) {
  try {
    await authApi.register({ email, password })
  } catch (err) {
    if (err.name === 'ConflictDocumentError') { throw new Error('Email already exists') }
    throw new Error('Unexpected Error')
  }
}
