import React from 'react'
import { useAuthContext } from '../context'
import session from '../session'

export function Main () {
  const { auth } = useAuthContext()

  return (
    <div>
      <h2>User</h2>
      <p>{JSON.stringify(auth.user)}</p>
      <h2>Access Key</h2>
      <p>{JSON.stringify(session.getAccessKey())}</p>
      <h2>Device Key</h2>
      <p>{JSON.stringify(session.getDeviceKey())}</p>
    </div>
  )
}
