import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import {
  Divider,
  Grid,
  Stack,
} from '@mui/material'

import api, { useWs } from '../../api'
import { Loading } from '../../components'
import { useAuthContext } from '../../context'
import { emitter } from '../../event'
import { Agent } from './Agent'
import { Bot, BotInfo } from './Bot'
import { Quotes } from './Balances'
import { Positions } from './Positions'
import { Orders, OpenOrders, PlaceOrder } from './Orders'
import { Actions } from './Actions'
import { remove, removeDuplicate, upsert } from './util'

export function Trading () {
  return (
    <div>
      <Main />
    </div>
  )
}

function Main () {
  const { auth } = useAuthContext()
  const token = useMemo(() => auth?.token, [auth])
  const {
    agent,
    agentId,
    agents,
    balances,
    bot,
    botId,
    bots,
    error,
    loading,
    openOrders,
    orders,
    allOrdersCount,
    filter,
    hiddenBot,
    loadMore,
    setAgent,
    setAgents,
    setAgentId,
    setBot,
    setBots,
    setBotId,
    setOpenOrder,
    setTickers,
    setFilter,
    setHiddenBot,
    tickers,
  } = useAssets()
  const agentBots = useMemo(
    () => bots.filter(({ agent_id: id }) => id === agentId),
    [agentId, bots],
  )
  const channels = useMemo(
    () => agent ? [`t:${agent.exchange}`] : [],
    [agent],
  )
  const onMessage = useCallback(({ m }) => setTickers(m), [setTickers])
  const title = useMemo(() => (
    'Trading ' +
    (agent?.exchange?.toUpperCase() ?? '') + ' ' +
    (bot?.market?.toUpperCase() ?? '') + ' ' +
    (bot?.label ?? '')
  ),
  [agent, bot],
  )
  const ticker = useMemo(
    () => tickers.find(({ m }) => m === bot?.market),
    [bot, tickers],
  )
  useEffect(() => {
    let pendingTime = 0
    const timer = setInterval(() => {
      pendingTime += 15
      emitter.emit('message', { severity: 'error', text: `No ticker data in ${pendingTime} seconds` })
      if (ticker !== undefined) setTickers(tickers.filter(({ m }) => m !== bot?.market))
    }, 15000)
    return () => { clearInterval(timer) }
  }, [ticker])
  useWs({ token, channels, onMessage })

  if (loading) return <Loading />
  if (error) return (<>Error: {error}</>)
  return (
    <>
      <Stack spacing={1}>
        <Typography variant="h6">{title}</Typography>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Agent
              agent={agent}
              agentId={agentId}
              agents={agents}
              setAgentId={setAgentId}
              setAgent={setAgent}
              setAgents={setAgents}
              setBotId={setBotId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Bot
              agent={agent}
              bot={bot}
              bots={agentBots}
              botId={botId}
              setBotId={setBotId}
              setBot={setBot}
              setBots={setBots}
              ticker={ticker}
              hiddenBot={hiddenBot}
            />
          </Grid>
        </Grid>
        <Actions agent={agent} bot={bot} setBot={setBot} ticker={ticker} />
        <BotInfo bot={bot} ticker={ticker} />
        <PlaceOrder
          agent={agent}
          bot={bot}
          setOpenOrder={setOpenOrder}
          ticker={ticker}
          balances={balances}
        />
        <Divider />
        <Grid container>
          <Grid item xs={12} lg={5} xl={4}>
            <Quotes bots={agentBots} balances={balances} />
            <Positions
              bots={agentBots}
              tickers={tickers}
              setOpenOrder={setOpenOrder}
              hiddenBot={hiddenBot}
              setHiddenBot={setHiddenBot}
            />
          </Grid>
          <Grid item xs={12} lg={7} xl={8}>
            <OpenOrders agents={agents} bots={bots} orders={openOrders} />
            <Orders orders={orders} allOrdersCount={allOrdersCount} loadMore={loadMore} filter={filter} setFilter={setFilter} />
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}

function useAssets () {
  const params = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [agents, setAgents] = useState([])
  const [balances, setBalances] = useState([])
  const [bots, setBots] = useState([])
  const [openOrders, setOpenOrders] = useState([])
  const [orders, setOrders] = useState([])
  const [allOrdersCount, setAllOrdersCount] = useState(0)
  const [filter, setFilter] = useState(-1)
  const [tickers, setTickers] = useState([])
  const [hiddenBot, setHiddenBots] = useState(localStorage.getItem('hidden-bot')?.split(',') ?? [])
  const agentId = useMemo(() => params.agentId ?? null, [params.agentId])
  const botId = useMemo(() => params.botId ?? null, [params.botId])
  const setAgentId = useCallback((id) => navigate(id), [navigate])
  const setBotId = useCallback(
    (id) => navigate(`${agentId}/${id}`),
    [agentId, navigate],
  )
  const agent = useMemo(
    () => agents.find(({ id }) => id === agentId),
    [agentId, agents],
  )
  const setAgent = useCallback(
    agent => setAgents(agents => upsert(agents, agent)),
    [setAgents],
  )
  const bot = useMemo(() => bots.find(({ id }) => id === botId), [botId, bots])
  const setBot = useCallback(
    bot => setBots(bots => upsert(bots, bot)),
    [setBots],
  )
  const setTicker = useCallback(
    ticker => setTickers(tickers => upsert(tickers, ticker, 'm')),
    [setTickers],
  )
  const setOrder = useCallback(
    order => {
      if (order.bot_id === botId) {
        setOrders(
          orders => upsert(orders, order)
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)))
      }
    }, [setOrders, botId],
  )
  const setHiddenBot = useCallback((hidden) => {
    localStorage.setItem('hidden-bot', hidden.join())
    setHiddenBots(hidden)
  }, [setHiddenBots])
  const setOpenOrder = useCallback(
    (order) => {
      if (order.status === 'created' || order.status === 'submitted') {
        setOpenOrders(orders => upsert(orders, order))
      } else {
        setOpenOrders(orders => remove(orders, order))
        setOrder(order)
      }
    },
    [setOrder, setOpenOrders],
  )
  const onBalances = useCallback(
    (balances) => {
      const updates = balances.filter(({ A }) => A === agentId)
      if (updates.length > 0) {
        setBalances(updates.map(b => ({
          id: b.I,
          agent_id: b.A,
          coin: b.c,
          free: b.f,
          locked: b.l,
          total: b.t,
        })))
      }
    },
    [agentId, setBalances],
  )
  const onOrders = useCallback(
    (orders) => orders.forEach(o => setOpenOrder({
      created_at: o.T,
      id: o.I,
      bot_id: o.B,
      exchange: o.E,
      market: o.M,
      side: o.S,
      status: o.s,
      price: o.p,
      quantity: o.q,
      total: o.t,
      average: o.a,
      filled: o.f,
      cost: o.c,
      commission_fee: o.g,
      commission_fee_coin: o.G,
      pnl: o.P,
      pnl_ratio: o.R,
    })),
    [setOpenOrder],
  )
  const onBots = useCallback(
    bots => bots.forEach(b => setBot({
      created_at: b.T,
      id: b.I,
      free: b.f,
      locked: b.l,
      cost: b.c,
      total: b.t,
      average_price: b.a,
      is_suspended: b.s,
      take_profit_state: b.tp ? { best_price: b.tp.b } : undefined,
    })),
    [setBot],
  )
  const filterParams = useMemo(
    () => filter === -1 ? {} : { begin: Date.now() - 24 * 60 * 60 * 1000 * filter, end: Date.now() },
    [filter])
  const loadMore = useCallback(
    async n => {
      let currentLength = orders.length
      let updatedOrders = [...orders]
      let updatedCount = allOrdersCount

      while (currentLength < n) {
        const res = await api.order.getOrders(botId, { order: 'desc', offset: updatedOrders.length, ...filterParams })
        updatedCount = res.pagination.count
        updatedOrders = removeDuplicate([...updatedOrders, ...res.body])
        currentLength = updatedOrders.length
      }
      setOrders(updatedOrders)
      setAllOrdersCount(updatedCount)
    }, [botId, orders, allOrdersCount, filterParams])

  useEffect(() => {
    setLoading(true)
    Promise
      .all([
        api.asset.getAgents(),
        api.asset.getBots(),
        api.order.getOpenOrders(),
      ])
      .then(([ar, br, or]) => {
        setAgents(ar.body)
        setBots(br.body)
        setOpenOrders(or.body)
      })
      .catch((err) => {
        setError(err)
        setAgents([])
        setBots([])
        setOpenOrders([])
      })
      .finally(() => setLoading(false))

    const intervalId = setInterval(() => {
      api.asset.getBots().then(br => {
        setBots(br.body)
      }).catch(() => {
        setBots([])
      })
      api.order.getOpenOrders().then(or => {
        setOpenOrders(or.body)
      }).catch(() => {
        setOpenOrders([])
      })
    }, 10000)

    emitter.on('orders', onOrders)
    emitter.on('bots', onBots)
    return () => {
      clearInterval(intervalId)
      emitter.off('orders', onOrders)
      emitter.off('bots', onBots)
    }
  }, [onBots, onOrders])

  useEffect(() => {
    if (agentId) {
      api
        .asset
        .getAgentBalances(agentId)
        .then(({ body }) => setBalances(body))
        .catch(() => setBalances([]))
    } else {
      setBalances([])
    }
    emitter.on('balances', onBalances)
    return () => {
      emitter.off('balances', onBalances)
    }
  }, [agentId, onBalances, setBalances])

  useEffect(() => {
    if (botId) {
      api
        .order
        .getOrders(botId, { order: 'desc', ...filterParams })
        .then((res) => {
          setAllOrdersCount(res.pagination.count)
          setOrders(res.body)
        })
        .catch(() => setOrders([]))
    } else {
      setOrders([])
    }
  }, [botId, setOrders, filterParams])

  return {
    agent,
    agentId,
    agents,
    balances,
    bot,
    botId,
    bots,
    error,
    loading,
    openOrders,
    orders,
    allOrdersCount,
    filter,
    hiddenBot,
    loadMore,
    setAgent,
    setAgentId,
    setAgents,
    setBalances,
    setBot,
    setBotId,
    setBots,
    setOpenOrder,
    setOpenOrders,
    setOrders,
    setTicker,
    setTickers,
    setFilter,
    setHiddenBot,
    tickers,
  }
}
