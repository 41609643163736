import React, { createContext, useCallback, useEffect, useState } from 'react'

import { useContext } from './context'
import { emitter } from '../event'

const MessagesContext = createContext()

export const useMessagesContext = () =>
  useContext('MessagesContext', MessagesContext)

export function MessagesContextProvider ({ children }) {
  const [messages, setMessages] = useState([])
  const pushMessages = useCallback((...now) => {
    const valid = now.filter(m => !!m?.text && !!m?.severity)
    if (valid.length > 0) setMessages(prev => ([...prev, ...valid]))
  }, [setMessages])

  useEffect(() => {
    console.log('@MessagesContextProvider.useEffect()')
    if (window.Notification && Notification.permission === 'default') {
      Notification.requestPermission().catch(console.error)
    }
    const onMessage = message => pushMessages(message)
    emitter.on('message', onMessage)
    return () => {
      emitter.off('message', onMessage)
    }
  }, [pushMessages])

  return (
    <MessagesContext.Provider value={{ messages, pushMessages, setMessages }}>
      {children}
    </MessagesContext.Provider>
  )
}
