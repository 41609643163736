import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { Box, Stack } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import api, { useApi } from '../../api'
import {
  Dropdown,
  EditDialog,
  TextInput,
  TextLabel,
  option,
} from './util'

export function AgentSelect ({ agent, agentId, agents, setAgentId, setBotId }) {
  const options = useMemo(
    () => agents.map(({ id: value, exchange, label }) => ({
      value,
      label: `${exchange.toUpperCase()} ${label ?? ''}`,
    })),
    [agents],
  )
  return (
    <Dropdown
      fullWidth
      label='agent'
      value={agentId ?? ''}
      setValue={id => setAgentId(id)}
      options={options}
    />
  )
}

export function AgentNew ({ setAgents }) {
  const [exchanges, setExchanges] = useState([])
  const [values, dispatch] = useReducer(
    (prev, now) => ({ ...prev, ...now }),
    {},
  )
  const submittable = useMemo(
    () => !!values.exchange_id && !!values.key && !!values.secret,
    [values],
  )
  const [fire] = useApi()
  useEffect(() => {
    api.asset.getExchanges().then(({ body }) => setExchanges(body))
  }, [setExchanges])

  return (
    <EditDialog
      disabled={false}
      title='Create a new agent'
      onClose={onClose}
      onSubmit={onSubmit}
      submittable={submittable}
      icon={<AddIcon />}
    >
      <Dropdown
        fullWidth
        label='exchange'
        value={values.exchange_id ?? null}
        setValue={value => dispatch({ exchange_id: value })}
        options={exchanges.map(({ id, name }) => ({ value: id, label: name }))}
      />
      <TextInput
        fullWidth
        label='Label'
        value={values.label ?? null}
        onChange={({ target: { value } }) => dispatch({ label: value })}
      />
      <TextInput
        fullWidth
        label='Sub-account'
        value={values.subaccount ?? null}
        onChange={({ target: { value } }) => dispatch({ subaccount: value })}
      />
      <TextInput
        fullWidth
        label='Key'
        value={values.key ?? null}
        onChange={({ target: { value } }) => dispatch({ key: value })}
      />
      <TextInput
        fullWidth
        label='Secret'
        value={values.secret ?? null}
        onChange={({ target: { value } }) => dispatch({ secret: value })}
      />
    </EditDialog>
  )

  function onClose (close) {
    close()
    dispatch({
      exchange_id: undefined,
      label: undefined,
      subaccount: undefined,
      key: undefined,
      secret: undefined,
    })
  }

  function onSubmit (close) {
    if (!values.exchange_id || !values.key || !values.secret) return

    fire(
      api
        .asset
        .createAgent(values)
        .then((res) => {
          setAgents(agents => [...agents, res.body])
          close()
          return res
        })
        .finally(() => close(false)),
      true,
      true,
    )
  }
}

export function AgentEdit ({ agent, setAgent }) {
  const [values, dispatch] = useReducer(
    (prev, now) => ({ ...prev, ...now }),
    {},
  )
  const [fire] = useApi()

  return (
    <EditDialog
      disabled={!agent?.id}
      title={`Agent ${agent?.id ?? ''}`}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <TextLabel fullWidth label='ID' value={agent?.id} />
      <TextLabel fullWidth label='Exchange' value={agent?.exchange} />
      <TextInput
        fullWidth
        label='Label'
        value={values?.label ?? agent?.label ?? '-'}
        onChange={({ target: { value } }) => dispatch({ label: value })}
      />
      <TextInput
        fullWidth
        label='Sub-account'
        value={values.subaccount ?? null}
        onChange={({ target: { value } }) => dispatch({ subaccount: value })}
      />
      <TextInput
        fullWidth
        label='Key'
        value={values?.key ?? agent?.key ?? ''}
        onChange={({ target: { value } }) => dispatch({ key: value })}
      />
      <TextInput
        fullWidth
        label='Secret'
        value={values?.secret ?? ''}
        onChange={({ target: { value } }) => dispatch({ secret: value })}
      />
    </EditDialog>
  )

  function onClose (close) {
    close()
    dispatch({
      label: undefined,
      subaccount: undefined,
      key: undefined,
      secret: undefined,
    })
  }

  function onSubmit (close) {
    if (!agent) return

    fire(
      api
        .asset
        .patchAgent(agent?.id, {
          ...option('label', values?.label),
          ...option('subaccount', values?.subaccount),
          ...option('key', values?.key),
          ...option('secret', values?.secret),
        })
        .then((res) => {
          setAgent(res.body)
          close()
          return res
        })
        .finally(() => close(false)),
      true,
      true,
    )
  }
}

export function Agent (props) {
  const {
    agent,
    agents,
    agentId,
    setAgent,
    setAgents,
    setAgentId,
    setBotId,
  } = props
  return (
    <Stack direction='row' alignItems='center'>
      <Box sx={{ mr: 1 }}><strong sx={{ mr: 2 }}>Agent</strong></Box>
      <AgentSelect
        agent={agent}
        agentId={agentId}
        agents={agents}
        setAgentId={setAgentId}
        setBotId={setBotId}
      />
      <AgentNew setAgents={setAgents} />
      <AgentEdit agent={agent} setAgent={setAgent} />
    </Stack>
  )
}
