import http from './http'

const test = {
  postTest,
}
export default test

export async function postTest (body = {}, query = {}) {
  return await http.post('test', { body, query })
}
