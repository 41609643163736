import React from 'react'
import { Navigate, BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { ThemeModeContextProvider, AuthContextProvider, useAuthContext, MessagesContextProvider } from './context'
import { Message } from './components/Message'
import Landing from './Landing'
import Dashboard from './Dashboard'
import { FullLoading } from './components'
import { SignIn } from './route'
import { SIGNIN, SIGNUP } from './path'
import Report from './Report'
import { SignUp } from './route/SignUp'

export default function App () {
  return (
    <ThemeModeContextProvider>
      <MessagesContextProvider>
        <BrowserRouter>
          <AuthContextProvider>
            <Routes>
              <Route index element={<Landing />} />
              <Route path={SIGNIN} element={<SignIn />} />
              <Route path={SIGNUP} element={<SignUp />} />
              <Route
                path='*' element={
                  <RequireAuth>
                    <Routes>
                      <Route path='/dashboard/*' element={<Dashboard />} />
                      <Route path='/report/*' element={<Report />} />
                    </Routes>
                  </RequireAuth>
                }
              />
            </Routes>
          </AuthContextProvider>
          <Message />
        </BrowserRouter>
      </MessagesContextProvider>
    </ThemeModeContextProvider>
  )
}

function RequireAuth ({ children }) {
  const { auth: { user, isLoading } } = useAuthContext()
  const location = useLocation()

  if (isLoading) return <FullLoading />
  if (!user) return <Navigate to={SIGNIN} state={{ from: location }} />
  return <>{children}</>
}
