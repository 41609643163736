import http from './http'

const order = {
  findOrder,
  getOrders,
  getOpenOrders,
  createOrder,
  cancelOrder,
}
export default order

export async function findOrder (id) {
  return await http.get(`orders/${id}`)
}

export async function getOrders (botId, params) {
  return await http.get('orders', { query: { bot_id: botId, ...params } })
}

export async function getOpenOrders () {
  return await http.get('open-orders')
}

export async function createOrder (botId, params) {
  return await http.post('orders', { body: { bot_id: botId, ...params } })
}

export async function cancelOrder (id) {
  return await http.delete(`orders/${id}`)
}
