import React, { useEffect, useMemo, useReducer, useState } from 'react'
import BigNumber from 'bignumber.js'
import {
  Box,
  Button,
  InputAdornment,
  Stack,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import { toFixed } from '../../util/index'
import api, { useApi } from '../../api'
import {
  Down,
  Dropdown,
  EditDialog,
  TextInput,
  TextLabel,
  Up,
  UpDown,
  getPnLDir,
  getPnL,
  option,
  parseMarket,
} from './util'

export function BotInfo ({ bot, ticker }) {
  const pnl = useMemo(() => getPnL(bot, ticker), [bot, ticker])

  if (!bot) return null
  return (
    <Box
      sx={{
        display: 'flex', gap: '10px',
      }}
    >
      <Box sx={{ width: 1 / 6 }}>
        Best ask/bid:<br/><Down><strong>{ticker?.a ?? '-'}</strong></Down>/
        <Up><strong>{ticker?.b ?? '-'}</strong></Up>
      </Box>
      <Box sx={{ display: 'flex', gap: '10px', flex: 1, '>div': { width: 1 / 6 } }}>
        <div>Free position:<br/><strong>{bot.free}</strong></div>
        <div>Locked position:<br/><strong>{bot.locked}</strong></div>
        <div>Total position:<br/><strong>{bot.total}</strong></div>
        <div>Avg. price:<br/><strong>{toFixed(bot.average_price, 4) ?? '-'}</strong></div>
        <div>Cost:<br/><strong>{toFixed(bot.cost, 2)}</strong></div>
        <div>P&L:<br/><UpDown
          dir={getPnLDir(pnl)}><strong>{toFixed(pnl, 2) ?? '-'}</strong></UpDown></div>
      </Box>
    </Box>
  )
}

export function BotSelect ({ botId, bots, setBotId, hiddenBot }) {
  const options = useMemo(() => {
    return bots
      .filter(bot => !hiddenBot.includes(bot.id))
      .map(({ id: value, market, label }) => ({
        value,
        label: `${market.toUpperCase()} ${label ?? ''}`,
      }))
  }, [bots, hiddenBot])
  return (
    <Dropdown
      fullWidth
      label='bot'
      value={botId ?? ''}
      setValue={id => setBotId(id)}
      options={options}
    />
  )
}

export function BotNew ({ agent, bots, setBots }) {
  const [markets, setMarkets] = useState([])
  const exchange = useMemo(() => agent?.exchange, [agent])
  const exchangeMarkets = useMemo(
    () => markets
      .filter(m => m.exchange === exchange)
      .filter(m => bots.find(b => m.name === b.market) === undefined),
    [bots, exchange, markets],
  )
  const [values, dispatch] = useReducer(
    (prev, now) => ({ ...prev, ...now }),
    {},
  )
  const submittable = useMemo(() => !!values.market_id, [values])
  const [fire] = useApi()
  useEffect(() => {
    api.asset.getMarkets().then(({ body }) => setMarkets(body))
  }, [setMarkets])

  return (
    <EditDialog
      disabled={!agent}
      title='Create a new bot'
      onClose={onClose}
      onSubmit={onSubmit}
      submittable={submittable}
      icon={<AddIcon />}
    >
      <Dropdown
        fullWidth
        label='market'
        value={values.market_id ?? null}
        setValue={value => dispatch({ market_id: value })}
        options={exchangeMarkets
          .map(({ id, name }) => ({ value: id, label: name }))}
      />
      <TextInput
        fullWidth
        label='Label'
        value={values.label ?? null}
        onChange={({ target: { value } }) => dispatch({ label: value })}
      />
    </EditDialog>
  )

  function onClose (close) {
    close()
    dispatch({ market_id: undefined, label: undefined })
  }

  function onSubmit (close) {
    if (!agent || !values.market_id) return

    fire(
      api
        .asset
        .createBot({
          agent_id: agent.id,
          market_id: values.market_id,
          ...option('label', values.label),
        })
        .then((res) => {
          setBots(bots => [...bots, res.body])
          close()
          return res
        })
        .finally(() => close(false)),
      true,
      true,
    )
  }
}

export function BotEdit ({ bot, setBot }) {
  const [values, dispatch] = useReducer(
    (prev, now) => ({ ...prev, ...now }),
    {},
  )
  const [fire] = useApi()

  return (
    <EditDialog
      disabled={!bot?.id}
      title={`Bot ${bot?.id ?? ''}`}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <TextLabel fullWidth label='ID' value={bot?.id} />
      <TextLabel fullWidth label='Market' value={bot?.market} />
      <TextInput
        fullWidth
        label='Label'
        value={values?.label ?? bot?.label ?? '-'}
        onChange={({ target: { value } }) => dispatch({ label: value })}
      />
    </EditDialog>
  )

  function onClose (close) {
    close()
    dispatch({ label: undefined })
  }

  function onSubmit (close) {
    if (!bot) return

    fire(
      api
        .asset
        .patchBot(bot?.id, { ...option('label', values?.label) })
        .then((res) => {
          setBot(res.body)
          close()
          return res
        })
        .finally(() => close(false)),
      true,
      true,
    )
  }
}

export function BotTransfer ({ agent, bot, bots }) {
  const quotes = useMemo(() => ['usd', 'usdt'], [])
  const base = useMemo(() => bot ? parseMarket(bot.market)[0] : null, [bot])
  const quote = useMemo(() => bot ? parseMarket(bot.market)[1] : null, [bot])
  const transferableBots = useMemo(
    () => bots.filter((b) => {
      const m = parseMarket(b.market)
      return b.id !== bot?.id &&
        b.agent_id === agent?.id &&
        b.type === 'spot' &&
        base === m[0] &&
        quotes.includes(m[1])
    }),
    [agent, base, bot, bots, quotes],
  )
  const transferable = useMemo(
    () => bot?.type === 'spot' &&
      new BigNumber(bot?.free).gt(0) &&
      quotes.includes(quote) &&
      transferableBots.length === 1,
    [bot, transferableBots, quote, quotes],
  )
  const [amount, setAmount] = useState(null)
  const [fire] = useApi()

  return (
    <EditDialog
      disabled={!transferable}
      title='Transfer position between bots'
      onClose={onClose}
      onSubmit={onSubmit}
      submittable={amount}
      icon={<CurrencyExchangeIcon />}
    >
      <div>From: {bot?.market}</div>
      <div>To: {transferableBots[0]?.market}</div>
      <TextInput
        fullWidth
        type='number'
        value={amount}
        onChange={({ target: { value } }) => setAmount(value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">Amount: </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button
                aria-label="max"
                onClick={() => setAmount(bot.free)}
                edge="end"
              >Max</Button>
            </InputAdornment>
          ),
        }}
      />
    </EditDialog>
  )

  function onClose (close) {
    close()
    setAmount(null)
  }

  function onSubmit (close) {
    if (transferableBots.length !== 1 || !amount) return

    fire(
      api
        .asset
        .transferBot({
          src_id: bot.id,
          dst_id: transferableBots[0].id,
          amount,
        })
        .then((res) => {
          close()
          return res
        })
        .finally(() => close(false)),
      true,
      true,
    )
  }
}

export function Bot ({ agent, bot, botId, bots, setBot, setBots, setBotId, ticker, hiddenBot }) {
  return (
    <Stack direction='row' alignItems='center'>
      <Box sx={{ ml: 2, mr: 1 }}><strong sx={{ mr: 2 }}>Bot</strong></Box>
      <BotSelect
        botId={botId}
        bots={bots}
        setBotId={setBotId}
        ticker={ticker}
        hiddenBot={hiddenBot}
      />
      <BotNew agent={agent} bots={bots} setBots={setBots} />
      <BotEdit bot={bot} setBot={setBot} />
      <BotTransfer agent={agent} bot={bot} bots={bots} />
    </Stack>
  )
}
