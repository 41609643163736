import { asset } from '../../api'
import React, { useEffect, useReducer, useState } from 'react'
import { Paper, Button, Box, Divider, TextField, Select, MenuItem } from '@mui/material'
import { allows } from '../../util/permission'
import { useMessagesContext } from '../../context'

export function PermissionManagement () {
  const { pushMessages } = useMessagesContext()
  const [agents, setAgents] = useState([])
  const [showList, setShowList] = useState(null)
  const [list, setList] = useState(null)
  const [values, setValues] = useReducer(
    (state, newState) => (({ ...state, ...newState })),
    { email: '', action: '' },
  )

  useEffect(() => {
    getAgents()
  }, [])

  const getAgents = () => {
    asset.getAgents().then((res) => setAgents(res.body))
      .catch(err => pushMessages({ severity: 'error', text: err.message }))
  }

  const getAgentPermission = (id) => {
    asset.getAgentPermission(id).then(res => setList(res.body))
      .catch(err => {
        pushMessages({ severity: 'error', text: err.message })
        setList(null)
        setShowList(null)
        getAgents()
      })
  }

  const onList = (id) => () => {
    setList(null)
    setShowList(id)
    setValues({ email: '', action: '' })
    getAgentPermission(id)
  }

  const onHide = () => {
    setList(null)
    setShowList(null)
    setValues({ email: '', action: '' })
  }

  const onChange = (e) => {
    const { name, value } = e.target
    setValues({ [name]: value })
  }

  const onAdd = (id) => () => {
    asset.updateAgentPermission(id, values).then(() => {
      setValues({ email: '', action: '' })
      getAgentPermission(id)
      pushMessages({ severity: 'success', text: 'add success' })
    }).catch(err => pushMessages({ severity: 'error', text: err.message }))
  }

  const onDelete = (id, email) => () => {
    asset.updateAgentPermission(id, { email, action: 'none' })
      .then(() => {
        getAgentPermission(id)
        pushMessages({ severity: 'success', text: 'delete success' })
      }).catch(err => pushMessages({ severity: 'error', text: err.message }))
  }

  return (
    <>
      <h2>Agent Permission Management</h2>
      <Paper sx={{ padding: '20px' }}>
        <Box sx={{ padding: '5px 0', display: 'flex', '>div': { width: '25%' }, fontWeight: 'bold' }}>
          <Box>Exchange</Box>
          <Box>Label</Box>
          <Box>Permission</Box>
          <Box />
        </Box>
        {agents.map((agent) => (
          <Box key={agent.id}>
            <Divider />
            <Box sx={{ padding: '5px 0', display: 'flex', alignItems: 'center', '>div': { width: '25%' } }}>
              <Box>{agent.exchange.toUpperCase()}</Box>
              <Box>{agent.label ?? ''}</Box>
              <Box>{agent.permission}</Box>
              <Box sx={{ '>button': { ml: '10px' }, display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" size="small" disabled={!allows(agent, 'manage')} onClick={
                  showList === agent.id ? onHide : onList(agent.id)}>{showList === agent.id ? 'Hide' : 'List'}</Button>
              </Box>
            </Box>
            {showList === agent.id && <Box sx={{ padding: '10px', margin: '5px 0 10px 0', border: '1px solid grey', borderRadius: '5px' }}>
              {list === null && <Box>Loading...</Box>}
              {list && list.map((v) => <Box key={v.email} sx={{ display: 'flex', gap: '10px', '>div': { width: '30%' } }}>
                <Box>{v.email}</Box>
                <Box>{v.action}</Box>
                <Button onClick={onDelete(agent.id, v.email)}>Delete</Button>
              </Box>)}
              {list && <Box sx={{ display: 'flex', gap: '10px', '>div': { width: '30%' } }}>
                <TextField size="small" required id="email" name="email" type="email" value={values.email} onChange={onChange} />
                <Select size="small" required id="action" name="action" value={values.action} onChange={onChange}>
                  <MenuItem value="manage">manage</MenuItem>
                  <MenuItem value="trade">trade</MenuItem>
                  <MenuItem value="view">view</MenuItem>
                </Select>
                <Button onClick={onAdd(agent.id)} disabled={values.email === '' || values.action === ''}>Add</Button>
              </Box>}
            </Box>
            }
          </Box>
        ))}
      </Paper>
    </>
  )
}
