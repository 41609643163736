const ftx = async (pairId, resolution, start, end) => {
  const res = await fetch(`https://ftx.potentia.tech/api/markets/${pairId}/candles?resolution=${resolution}&start_time=${start}&end_time=${end}`)
  const data = await res.json()
  return data.result.map((v) => ({
    time: v.time,
    low: v.low,
    high: v.high,
    open: v.open,
    close: v.close,
    volume: v.volume,
  }))
}

/**
 * Datafeed is an object you supply to the TradingView Widget.
 * It has a set of methods like "getBars" or "resolveSymbol" that are called by
 * the Charting Library in certain cases. The datafeed returns results using
 * callback functions.
 */
export const datafeed = {
  /**
   * This method is used by the Charting Library to get a configuration of your
   * datafeed (e.g. supported resolutions, exchanges and so on). This is the
   * first method of the datafeed that is called.
   */
  onReady: (callback) => {
    const config = {
      supported_resolutions: [
        '15',
        '60',
        '240',
        '1D',
        '1W',
      ],
      currency_codes: ['USD'],
      supports_time: true,
    }
    setTimeout(() => callback(config), 100)
  },
  /**
   * This method is used by the library to retrieve information about a specific
   * symbol (exchange, price scale, full symbol etc.)
   */
  resolveSymbol: (symbolName, onResolve) => {
    const symbolObject = {
      name: symbolName,
      full_name: symbolName,
      type: symbolName,
      session: '24x7',
      exchange: 'BOT',
      listed_exchange: 'BOT',
      timezone: 'Asia/Taipei',
      format: 'price',
      minmov: 1,
      pricescale: 10000,
      has_intraday: true,
      intraday_multipliers: ['1', '5', '15', '30', '60', '240', '360'],
      supported_resolutions: [
        '15',
        '60',
        '240',
        '1D',
        '1W',
      ],
      has_empty_bars: true,
      volume_precision: 3,
    }
    setTimeout(() => onResolve(symbolObject))
  },
  /**
   * This method is used by the charting library to get historical data for the symbol.
   */
  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onResult,
  ) => {
    let param = 900
    if (resolution === '15') param = 900
    else if (resolution === '60') param = 3600
    else if (resolution === '240') param = 14400
    else if (resolution === '1D') param = 86400
    try {
      const bars = await ftx(symbolInfo.name, param, periodParams.from, periodParams.to)
      if (bars.length === 0) {
        // "noData" should be set if there is no data in the requested period.
        onResult([], { noData: true })
        return
      }
      onResult(bars, { noData: false })
    } catch (error) {
      console.warn('[getBars]: Get error', error)
    }
  },
  /**
   * This method is used by the Charting Library to search symbols every time a user
   * types a text in the symbol search box. Changing symbols also works using the
   * symbol search.
   */
  searchSymbols: () => { },
  /**
   * Charting Library calls this function when it wants to receive real-time updates
   * for a symbol. The Library assumes that you will call onRealtimeCallback every
   * time you want to update the most recent bar or to add a new one.
   */
  subscribeBars: () => { },
  /**
   * Charting Library calls this function when it doesn't want to receive updates for
   * this subscriber any more. subscriberUID will be the same object that Library
   * passed to subscribeBars before.
   */
  unsubscribeBars: () => { },
}
