import { emitter } from '../event'
import session from '../session'
import { sign } from '../util'

const http = {
  async get (path, options = {}) {
    return await api('GET', path, options)
  },

  async post (path, options = {}) {
    return await api('POST', path, options)
  },

  async patch (path, options = {}) {
    return await api('PATCH', path, options)
  },

  async delete (path, options = {}) {
    return await api('DELETE', path, options)
  },

  api,
  request,
  refreshAccessKey,
}
export default http

async function api (method, path, options = {}) {
  try {
    const key = session.getAccessKey()
    return await request(
      method,
      path,
      {
        ...options,
        token: options.token ?? key?.token,
        secret: options.secret ?? key?.secret,
      },
    )
  } catch (err) {
    if (err.status === 401) {
      // invalid access-key, try to refresh it
      session.removeAccessKey()
      if (refreshAccessKey(options)) return api(method, path, options)
      // unable to refresh the access-key, logout
      emitter.emit('logout')
    } else {
      throw err
    }
  }
}

export async function request (method, path, options = {}) {
  const query = new URLSearchParams(options.query ?? {})
  const url = `/api/${path}?${query.toString()}`
  const body = JSON.stringify(options.body ?? (hasBody(method) ? {} : ''))
  const headers = {
    'x-api-version': '1',
    'content-type': 'application/json',
  }
  if (options.token) headers['x-api-token'] = options.token
  if (options.token && options.secret) {
    const { token, secret } = options
    const now = (new Date()).toISOString()
    const host = window.location.host
    const buffer = [token, now, host, method, url, body].join(' ')
    headers['x-api-timestamp'] = now
    const algo = 'sha512'
    headers['x-api-signature'] = `${algo}=${sign(secret, buffer, algo)}`
  }
  const res = await fetch(url, {
    method,
    headers,
    ...(hasBody(method) ? { body } : {}),
  })
  if (res.ok) {
    const { headers, status } = res
    const pagination = headers.get('x-pagination-count') === null
      ? undefined
      : {
          offset: parseInt(headers.get('x-pagination-offset')),
          limit: parseInt(headers.get('x-pagination-limit')),
          count: parseInt(headers.get('x-pagination-count')),
          order: headers.get('x-pagination-order'),
        }
    return {
      body: res.status === 204 ? undefined : await res.json(),
      status,
      ...(pagination === undefined ? {} : { pagination }),
    }
  }

  const json = await res.json()
  const err = new Error(json.message)
  err.status = json.status
  err.code = json.code
  err.name = json.name
  if (json.missing) err.missing = json.missing
  if (json.invalid) err.invalid = json.invalid
  throw err
}

export async function refreshAccessKey ({ token } = {}) {
  try {
    const { body } = await request(
      'POST',
      'auth/access-key',
      { token: token ?? session.getDeviceKey()?.token },
    )
    session.setAccessKey(body)
    return true
  } catch (err) {
    return false
  }
}

function hasBody (method) {
  return method !== 'GET' && method !== 'HEAD'
}
