import React, { useCallback, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import api, { useApi } from '../../api'
import { UpDown, getPnLDir, getPnL } from './util'
import { toFixed } from '../../util/index'

export function Positions ({ bots, tickers, setOpenOrder, hiddenBot, setHiddenBot }) {
  const [showAll, setShowAll] = useState(false)
  const listed = useMemo(() =>
    bots
      .sort((a, b) => {
        const ta = new BigNumber(a.total)
        const tb = new BigNumber(b.total)
        if (ta.isZero() && tb.isZero()) return cmp(a.market, b.market)
        if (ta.isZero()) return 1
        if (tb.isZero()) return -1
        return cmp(a.market, b.market)
      }),
  [bots],
  )
  if (listed.length === 0) return null
  return (
    <TableContainer component={Paper} sx={{ my: 1 }}>
      <Box sx={{ textAlign: 'right' }}>
        <FormControlLabel control={<Checkbox checked={showAll} onChange={(e) => setShowAll(e.target.checked)} />} label="Show All" />
      </Box>
      <Table size="small" aria-label="a dense table">
        <caption>Positions</caption>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box>Market</Box>
              <Box>Position</Box>
            </TableCell>
            <TableCell align="right">
              <Box>Avg.&nbsp;Price</Box>
              <Box>Price</Box>
            </TableCell>
            <TableCell align="right">
              <Box>Cost</Box>
              <Box>Total</Box>
            </TableCell>
            <TableCell align="right">P&L</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listed
            .filter(bot => showAll ? true : !hiddenBot.includes(bot.id))
            .map(bot => <Position
              key={bot.id}
              bot={bot}
              tickers={tickers}
              setOpenOrder={setOpenOrder}
              hiddenBot={hiddenBot}
              setHiddenBot={setHiddenBot}
            />)}
        </TableBody>
      </Table>
    </TableContainer>
  )

  function cmp (a, b) {
    return a === b ? 0 : (a < b ? -1 : 1)
  }
}

export function Position ({ bot, tickers, setOpenOrder, hiddenBot, setHiddenBot }) {
  const [fire, updating] = useApi()
  const dir = useMemo(() => {
    const t = new BigNumber(bot?.total)
    return t.isNaN() || t.isZero() ? '' : (t.gt(0) ? '+' : '-')
  }, [bot])
  const ticker = useMemo(() => tickers.find(({ m }) => m === bot?.market), [tickers])
  const price = useMemo(
    () => {
      if (dir === '+') return ticker?.b
      if (dir === '-') return ticker?.a
      const a = new BigNumber(ticker?.a)
      const b = new BigNumber(ticker?.b)
      if (a.isNaN() && b.isNaN()) return null
      if (a.isNaN()) return b.toFixed()
      if (b.isNaN()) return a.toFixed()
      return a.plus(b).div(2).toFixed()
    },
    [bot, dir, ticker],
  )
  const amount = useMemo(() => bot?.total ?? 0, [bot])
  const total = useMemo(
    () => (price && amount
      ? new BigNumber(amount).times(price).toFixed()
      : 0),
    [price, amount],
  )
  const cost = useMemo(() => bot?.cost, [bot])
  const pnl = useMemo(() => getPnL(bot, ticker), [bot, ticker])
  const disabled = useMemo(
    () => {
      const n = new BigNumber(bot?.free)
      return updating || n.isNaN() || n.isZero()
    },
    [bot, updating],
  )
  const onVisibility = useCallback(() => {
    let newHiddenBot
    if (hiddenBot.includes(bot.id)) newHiddenBot = hiddenBot.filter((v) => v !== bot.id)
    else newHiddenBot = [...hiddenBot, bot.id]
    setHiddenBot(newHiddenBot)
  }, [bot, hiddenBot, setHiddenBot])

  return (
    <TableRow>
      <TableCell>
        <Box><Market market={bot.market} /></Box>
        <Box><UpDown dir={dir}>{amount}</UpDown></Box>
      </TableCell>
      <TableCell align="right">
        <Box>{toFixed(bot?.average_price, 4) ?? '-'}</Box>
        <Box><UpDown dir={dir}>{price ?? '-'}</UpDown></Box>
      </TableCell>
      <TableCell align="right">
        <Box><UpDown dir={dir}>{toFixed(cost, 2)}</UpDown></Box>
        <Box><UpDown dir={dir}>{toFixed(total, 2)}</UpDown></Box>
      </TableCell>
      <TableCell align="right">
        <UpDown dir={getPnLDir(pnl)}>{toFixed(pnl, 2) ?? '-'}</UpDown>
      </TableCell>
      <TableCell align="center">
        <Box>
          <Button
            disabled={disabled}
            onClick={() => submit(bot)}
          >Close</Button>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={onVisibility}>
          {hiddenBot.includes(bot.id) ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </Box>
      </TableCell>
    </TableRow>
  )

  function submit (bot) {
    const q = new BigNumber(bot?.free)
    if (!bot || !bot?.id || q.isNaN() || q.isZero()) return
    fire(
      api
        .order
        .createOrder(bot?.id, { quantity: q.negated().toFixed() })
        .then(res => {
          setOpenOrder(res.body)
          return res
        }),
      true,
      true,
    )
  }
}

function Market ({ market }) {
  const [m, p] = market.split('-')
  return <>{m} {p ? `(${p})` : null}</>
}
