import React, { createContext, useCallback, useEffect, useMemo, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

import { useWs } from '../api'
import { emitter } from '../event'
import { SIGNIN } from '../path'
import service from '../service'
import { useContext } from './context'

const AuthContext = createContext()

export const useAuthContext = () => useContext('AuthContext', AuthContext)

export function AuthContextProvider ({ children }) {
  const navigate = useNavigate()
  const [auth, setAuth] = useReducer(
    (prev, now) => ({ ...prev, ...now }),
    { isLoading: true },
  )

  const token = useMemo(() => auth?.token, [auth])
  const channels = useMemo(() => ['u'], [])
  const onMessage = useCallback(({ c, m }) => {
    if (c === `u:${auth?.user?.id ?? ''}`) {
      const { B, b, o } = m
      if (B) emitter.emit('bots', B)
      if (b) emitter.emit('balances', b)
      if (o) emitter.emit('orders', o)
    }
  }, [auth])
  useWs({ token, channels, onMessage })

  useEffect(() => {
    service
      .auth
      .signIn(setAuth)
      .catch(console.error)
      .finally(() => setAuth({ isLoading: false }))

    const onLogout = () => service
      .auth
      .signOut(setAuth)
      .then(() => navigate(SIGNIN))
      .catch(console.error)

    emitter.on('logout', onLogout)
    return () => {
      emitter.off('logout', onLogout)
    }
  }, [navigate, setAuth])

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  )
}
