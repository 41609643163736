import React from 'react'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import { Alert, Avatar, Box, Container, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import QRCode from 'react-qr-code'

export function EnableTwoFa ({ values, twoFaValues, onConfirm, onChange, loading, error }) {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <VpnKeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Enable 2-factor authentication
        </Typography>
        <Box sx={{ p: '10px', textAlign: 'center' }}>
          <p>please scan the QR code, note the secret, and input the code to confirm 2-factor authentication.</p>
          <p>{twoFaValues.secret}</p>
          <Box sx={{ p: '10px', bgcolor: '#ffffff' }}>
            <QRCode value={twoFaValues.uri} />
          </Box>
        </Box>
        <Box component="form" onSubmit={onConfirm} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="otp"
            label="code"
            name="otpCode"
            autoFocus
            autoComplete='off'
            onChange={onChange}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loading={loading}
            disabled={values.optCode === ''}
            sx={{ mt: 3, mb: 2 }}
          >
            Confirm
          </LoadingButton>
          <div>
            {error &&
              <Alert severity="error">{error}</Alert>
            }
          </div>
        </Box>
      </Box>
    </Container>
  )
}
