import BigNumber from 'bignumber.js'

export function getActionsError (bot, action, method, field, input, protection = true) {
  const total = new BigNumber(bot.total)
  if (input === undefined) return 'missing value'

  const status = total.gt(0) ? 'long' : 'short'
  const type = input.charAt(0)
  const value = new BigNumber(input.slice(1))

  if (value.isNaN()) {
    return 'invalid value'
  }

  if (action === 'take-profit') {
    if (method === '1') {
      if (field === 'trigger-price') {
        switch (type) {
          case '$':
            if (value.lte(0)) return 'trigger price should be positive'
            if (status === 'long' && protection && value.lte(bot.average_price)) return `trigger price should be greater than avg. price (${bot.average_price}) for long`
            if (status === 'short' && protection && value.gte(bot.average_price)) return `trigger price should be less than avg. price (${bot.average_price}) for short`
            return null
          case '+':
            if (status === 'long' && protection && value.lte(0)) return 'offset should be positive for long'
            if (status === 'short' && protection && value.gte(0)) return 'offset should be negative for short'
            return null
          case '%':
            if (status === 'long' && protection && value.lte(1)) return '% should be greater than 1 for long'
            if (status === 'short' && protection && (value.gte(1) || value.lte(0))) return '% should be between 0 and 1 for short'
            return null
          default:
            return null
        }
      }
      if (field === 'quantity') {
        if (value.gt(1) || value.lte(0)) return '0 < ratio ≤ 1'
      }
    }
    if (method === '2') {
      if (field === 'trigger-price') {
        switch (type) {
          case '$':
            if (value.lte(0)) return 'trigger price should be positive'
            if (status === 'long' && protection && value.lte(bot.average_price)) return `trigger price should be greater than avg. price (${bot.average_price}) for long`
            if (status === 'short' && protection && value.gte(bot.average_price)) return `trigger price should be less than avg. price (${bot.average_price}) for short`
            return null
          case '+':
            if (status === 'long' && protection && value.lte(0)) return 'offset should be positive for long'
            if (status === 'short' && protection && value.gte(0)) return 'offset should be negative for short'
            return null
          case '%':
            if (status === 'long' && protection && value.lte(1)) return '% should be greater than 1 for long'
            if (status === 'short' && protection && (value.gte(1) || value.lte(0))) return '% should be between 0 and 1 for short'
            return null
          default:
            return null
        }
      }
      if (field === 'retracement-price') {
        switch (type) {
          case '$':
            if (value.lte(0)) return 'trigger price should be positive'
            if (status === 'long' && protection && value.lte(bot.average_price)) return `trigger price should be greater than avg. price (${bot.average_price}) for long`
            if (status === 'short' && protection && value.gte(bot.average_price)) return `trigger price should be less than avg. price (${bot.average_price}) for short`
            return null
          case '+':
            if (status === 'long' && protection && value.gte(0)) return 'offset should be negative for long'
            if (status === 'short' && protection && value.lte(0)) return 'offset should be positive for short'
            return null
          case '%':
            if (status === 'long' && protection && (value.gte(1) || value.lte(0))) return '% should be between 0 and 1 for long'
            if (status === 'short' && protection && (value.gte(2) || value.lte(1))) return '% should be between 1 and 2 for short'
            return null
          default:
            return null
        }
      }
      if (field === 'quantity') {
        if (value.gt(1) || value.lte(0)) return '0 < ratio ≤ 1'
      }
    }
  }

  if (action === 'stop-loss') {
    if (field === 'trigger-price') {
      switch (type) {
        case '$':
          if (value.lte(0)) return 'trigger price should be positive'
          if (status === 'long' && protection && value.gte(bot.average_price)) return `trigger price should be less than avg. price (${bot.average_price}) for long`
          if (status === 'short' && protection && value.lte(bot.average_price)) return `trigger price should be greater than avg. price (${bot.average_price}) for short`
          return null
        case '+':
          if (status === 'long' && protection && value.gte(0)) return 'offset should be negative for long'
          if (status === 'short' && protection && value.lte(0)) return 'offset should be positive for short'
          return null
        case '%':
          if (status === 'long' && protection && (value.gte(1) || value.lte(0))) return '% should be between 0 and 1 for long'
          if (status === 'short' && protection && value.lte(1)) return '% should be greater than 1 for short'
          return null
        default:
          return null
      }
    }
  }
  return null
}
