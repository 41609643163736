import { Avatar, Box, TextField, Typography, Alert } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import LockOutlined from '@mui/icons-material/LockOutlined'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { signUp } from '../../service/auth'
import { SIGNIN } from '../../path'
import { useMessagesContext } from '../../context'

export function SignUpForm () {
  const { pushMessages } = useMessagesContext()
  const [loading, setLoading] = useState(false)
  const { control, handleSubmit, formState: { errors }, setError } = useForm()
  const [signUpError, setSignUpError] = useState()
  const navigate = useNavigate()

  const onSubmit = data => {
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', { type: 'inconsistent' }, { shouldFocus: true })
      return
    }
    setLoading(true)
    signUp(data.email, data.password)
      .then(() => {
        pushMessages({ severity: 'success', text: 'success, please login' })
        navigate(SIGNIN)
      })
      .catch((err) => {
        setSignUpError(err.message)
        setLoading(false)
      })
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => <TextField
            {...field}
            margin="normal"
            fullWidth
            label="Email Address"
            autoComplete="email"
            error={!!errors.email}
            autoFocus
          />}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => <TextField
            {...field}
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            error={!!errors.password}
          />}
        />
        <Controller
          name="confirmPassword"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => <TextField
            {...field}
            margin="normal"
            fullWidth
            label="Confirm Password"
            type="password"
            error={!!errors.confirmPassword}
          />}
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          loading={loading}
          disabled={loading}
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </LoadingButton>
      </form>
      {signUpError && <Alert severity="error">{signUpError}</Alert>}
    </Box>
  )
}
