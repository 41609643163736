import React from 'react'
import LockOutlined from '@mui/icons-material/LockOutlined'
import { useNavigate } from 'react-router-dom'
import { Alert, Avatar, Box, Container, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { SIGNUP } from '../../path'

export function SignInForm ({ values, onSubmit, onChange, loading, error, stepOtp }) {
  const navigate = useNavigate()

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={onSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            disabled={stepOtp}
            onChange={onChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={stepOtp}
            onChange={onChange}
          />
          {stepOtp && <TextField
            margin="normal"
            required
            fullWidth
            id="otp"
            label="code"
            name="otpCode"
            autoFocus
            autoComplete='off'
            onChange={onChange}
          />}
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loading={loading}
            disabled={(values.email === '' || values.password === '') || (stepOtp && values.otpCode === '')}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>
          <div>
            {error &&
              <Alert severity="error">{error}</Alert>
            }
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='body2' sx={{ ':hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
              Forgot password?
            </Typography>
            <Typography variant='body2' sx={{ ':hover': { cursor: 'pointer', textDecoration: 'underline' } }} onClick={() => navigate(`/${SIGNUP}`)}>
              {"Don't have an account? Sign Up"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
