import React, { useEffect, useState } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { useMessagesContext } from '../context'

export function Message () {
  const { messages, setMessages } = useMessagesContext()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(undefined)

  useEffect(() => {
    if (messages.length && !message) {
      // Set a new snack when we don't have an active one
      setMessage({ ...messages[0] })
      setMessages(prev => prev.slice(1))
      setOpen(true)
    } else if (messages.length && message && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
  }, [messages, setMessages, message, open])

  const handleClose = (e, r) => r === 'clickaway' ? undefined : setOpen(false)
  const handleExited = () => setMessage(undefined)

  return (
    <Snackbar
      key={message?.key}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert
        onClose={handleClose}
        severity={message?.severity} sx={{ width: '100%' }}
      >
        {message?.text}
      </Alert>
    </Snackbar>
  )
}
