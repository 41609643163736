import { Divider } from '@mui/material'
import React from 'react'
import { ChangePassword } from './ChangePassword'
import { PermissionManagement } from './PermissionManagement'

export function Config () {
  return (
    <>
      <ChangePassword />
      <Divider sx={{ mt: 3, mb: 3 }} />
      <PermissionManagement />
      <Divider sx={{ mt: 3, mb: 3 }} />
    </>
  )
}
