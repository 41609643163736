import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Main } from './reportRoute'
import { ReportContainer } from './components'
import { Transactions } from './reportRoute/Transactions'

export default function Report () {
  return (
    <ReportContainer>
      <Routes>
        <Route index element={<Main />} />
        <Route path=':agentId' element={<Transactions />} />
      </Routes>
    </ReportContainer>
  )
}
