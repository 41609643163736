import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EditIcon from '@mui/icons-material/Edit'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { isEmpty, isNil } from 'ramda'

export function Block ({ children }) {
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      {children}
    </Paper>
  )
}

export function Dropdown ({ label, value, setValue, options, ...props }) {
  return (
    <Select
      {...props}
      variant='standard'
      label={label}
      value={value}
      onChange={({ target: { value } }) => setValue(value)}
    >
      {options.map(({ value, label }) =>
        <MenuItem value={value} key={value}>{label}</MenuItem>)}
    </Select>
  )
}

export function EditButtons ({ editing, toggle, submit }) {
  return (
    <>
      <IconButton
        color={editing ? 'secondary' : 'primary'}
        aria-label={editing ? 'edit' : 'cancel'}
        component="span"
        onClick={toggle}
      >
        {editing ? <CancelIcon /> : <EditIcon />}
      </IconButton>
      {editing && (<IconButton
        color="primary"
        aria-label="save"
        component="span"
        onClick={submit}
      >
        <CheckCircleIcon />
      </IconButton>
      )}
    </>
  )
}

export function EditDialog (props) {
  const {
    icon,
    disabled,
    title,
    onClose,
    onSubmit,
    submittable = true,
    children,
  } = props
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  return (
    <>
      <IconButton
        disabled={disabled}
        color={disabled ? 'primary' : 'secondary'}
        aria-label='edit'
        component="span"
        onClick={() => setOpen(true)}
      >
        {icon ?? <EditIcon />}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button disabled={submitting} onClick={handleClose} >Cancel</Button>
          <Button
            disabled={submitting || !submittable}
            onClick={handleSubmit}
          >Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  )

  function close (noclose = false) {
    setSubmitting(false)
    if (!noclose) setOpen(false)
  }

  function handleClose () {
    onClose(close)
  }

  function handleSubmit () {
    setSubmitting(true)
    onSubmit(close)
  }
}

export function TextLabel (props) {
  return <TextInput disabled {...props} />
}

export function TextInput (props) {
  return <TextField margin='normal' size='small' {...props} />
}

export function UpDown ({ dir, children }) {
  if (dir === '+') return <Up>{children}</Up>
  if (dir === '-') return <Down>{children}</Down>
  return <>{children}</>
}

export function Up ({ children }) {
  return <Typography variant='span' color='up.main'>{children}</Typography>
}

export function Down ({ children }) {
  return <Typography variant='span' color='down.main'>{children}</Typography>
}

export function getPnLDir (v) {
  const n = new BigNumber(v)
  return n.gt(0) ? '+' : (n.lt(0) ? '-' : '')
}

export function option (key, value) {
  return isNil(value) || isEmpty(value) ? {} : { [key]: value }
}

export function upsert (items, item, key = 'id') {
  const i = items.findIndex((it) => it[key] === item[key])
  const r = i < 0
    ? [...items, item]
    : [...items.slice(0, i), { ...items[i], ...item }, ...items.slice(i + 1)]
  return r
}

export function remove (items, item, key = 'id') {
  const i = items.findIndex((it) => it[key] === item[key])
  return i < 0
    ? items
    : [...items.slice(0, i), ...items.slice(i + 1)]
}

export function removeDuplicate (arr) {
  return arr.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
}

export function parseMarket (market) {
  return market.split('-')[0].split('/')
}

export function getPnL (bot, ticker) {
  if (bot?.total === undefined || bot?.cost === undefined || ticker?.a === undefined || ticker?.b === undefined) return '0'
  const total = new BigNumber(bot?.total)
  const price = total.gt(0) ? ticker?.b : ticker?.a

  return total.times(price).minus(bot?.cost).toFixed()
}
