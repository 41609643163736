export function allows (agent, action) {
  const permission = agent?.permission
  if (permission === undefined) return false
  const perms = {
    manage: ['manage', 'trade', 'view'],
    trade: ['trade', 'view'],
    view: ['view'],
  }
  return perms[permission].includes(action)
}
