import http, { refreshAccessKey } from './http'

export { refreshAccessKey }

const auth = {
  login,
  logout,
  refreshAccessKey,
  enableOtp,
  confirmOtp,
  changePassword,
  register,
}
export default auth

export async function login ({ email, password, otpCode }) {
  // use request to avoid the 401 redirection
  return await http.request('POST', 'auth/login', { body: { email, password, otp_code: otpCode } })
}

export async function logout () {
  return await http.post('auth/logout')
}

export async function enableOtp ({ email, password }) {
  return await http.request('POST', 'auth/otp', { body: { email, password } })
}

export async function confirmOtp ({ email, password, otpCode }) {
  return await http.request('PATCH', 'auth/otp', { body: { email, password, otp_code: otpCode } })
}

export async function changePassword ({ oldPassword, newPassword, otpCode }) {
  return await http.patch('auth/password', {
    body: {
      old_password: oldPassword,
      new_password: newPassword,
      otp_code: otpCode,
    },
  })
}

export async function register (body) {
  return await http.request('POST', 'auth/register', { body })
}
