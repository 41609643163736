import { useEffect, useMemo, useState } from 'react'

import useWebSocket from 'react-use-websocket'
import { isNil } from 'ramda'

import { emitter } from '../event'
import http from './http'
import asset from './asset'
import auth from './auth'
import user from './user'
import order from './order'
import report from './report'

import test from './test'

const api = {
  http,
  asset,
  auth,
  order,
  user,
  report,

  test,
}

export {
  http,
  asset,
  auth,
  order,
  user,
  report,

  test,
}
export default api

export function useApi () {
  const [values, setValues] = useState({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(undefined)
  const [error, setError] = useState(undefined)
  useEffect(() => {
    if (values?.promise) {
      setLoading(true)
      values.promise
        .then((res) => {
          setResponse(res)
          if (values?.onSuccess !== false) {
            const text = textify(values?.onSuccess, res, 'Success!')
            emitter.emit('message', { severity: 'success', text })
          }
        })
        .catch((err) => {
          setError(err)
          if (values?.onError !== false) {
            const text = textify(values?.onError, err, textifyError(err))
            emitter.emit('message', { severity: 'error', text })
          }
        })
        .finally(() => setLoading(false))
    }
  }, [values])
  return [fire, loading, response, error]

  function fire (promise, onSuccess = false, onError = false) {
    setResponse(undefined)
    setValues({ promise, onSuccess, onError })
  }

  function textify (exec, arg, fallback) {
    if (typeof exec === 'string') return exec
    if (typeof exec === 'function') return exec(arg)
    return fallback
  }

  function textifyError (err) {
    if (isNil(err)) return 'Unknown error'
    if (typeof err === 'object') {
      const message = err?.message ?? 'Unknown error'
      const extra = []
      if (Array.isArray(err?.missing)) {
        extra.push(`missing: ${err?.missing.join(', ')}`)
      }
      if (Array.isArray(err?.invalid)) {
        extra.push(`invalid: ${err?.invalid.join(', ')}`)
      }
      return `${message}${extra.length > 0 ? ': ' : ''}${extra.join('; ')}`
    }
    return String(err)
  }
}

export function useWs ({ token, channels, onMessage }) {
  const link = useMemo(() =>
    Array.isArray(channels) && channels.length > 0 && token
      ? `${window.location.origin.replace(/^http/, 'ws')}/ws/${channels.join(',')}`
      : null,
  [channels, token],
  )
  useWebSocket(link, {
    protocols: token,
    shouldReconnect: () => true,
    onMessage: ({ data }) => onMessage(JSON.parse(data)),
    onOpen: () => {
      console.info(`${new Date().toISOString()} ${channels.join()} ws connected`)
      emitter.emit('message', { severity: 'success', text: `${channels.join()} ws connected` })
    },
    onClose: () => {
      console.info(`${new Date().toISOString()} ${channels.join()} ws disconnected`)
      emitter.emit('message', { severity: 'error', text: `${channels.join()} ws disconnected` })
    },
    onError: () => {
      console.info(`${new Date().toISOString()} ${channels.join()} ws error`)
    },
  })
}
