import { LoadingButton } from '@mui/lab'
import { Box, TextField } from '@mui/material'
import React, { useReducer, useState } from 'react'
import { auth } from '../../api'
import { useMessagesContext } from '../../context'

export function ChangePassword () {
  const { pushMessages } = useMessagesContext()
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { oldPassword: '', newPassword: '', confirmNewPassword: '', otpCode: '' },
  )

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await auth.changePassword(values)
      pushMessages({ severity: 'success', text: 'success' })
      setValues({ oldPassword: '', newPassword: '', confirmNewPassword: '', otpCode: '' })
    } catch (err) {
      pushMessages({ severity: 'error', text: err.message })
    } finally {
      setLoading(false)
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target
    setValues({ [name]: value })
  }

  return (
    <>
      <h2>Change Password</h2>
      <Box component="form" onSubmit={onSubmit} noValidate sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <TextField
          margin="normal"
          required
          id="oldPassword"
          name="oldPassword"
          label="Old Password"
          type="password"
          value={values.oldPassword}
          onChange={onChange}
        />
        <TextField
          margin="normal"
          required
          id="newPassword"
          name="newPassword"
          label="New Password"
          type="password"
          value={values.newPassword}
          onChange={onChange}
        />
        <TextField
          margin="normal"
          required
          id="confirmNewPassword"
          name="confirmNewPassword"
          label="Confirm New Password"
          type="password"
          onChange={onChange}
          value={values.confirmNewPassword}
          error={values.newPassword !== values.confirmNewPassword}
        />
        <TextField
          margin="normal"
          required
          id="otpCode"
          name="otpCode"
          label="2FA Code"
          autoComplete='off'
          value={values.otpCode}
          onChange={onChange}
        />
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={values.oldPassword === '' ||
            values.newPassword === '' ||
            values.confirmNewPassword === '' ||
            values.otpCode === '' ||
            values.newPassword !== values.confirmNewPassword}
          loading={loading}
        >Change</LoadingButton>
      </Box>
    </>
  )
}
