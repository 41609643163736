import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Config, Main, Trading, TradingView } from './route'
import { CONFIG, TRADING, TRADING_VIEW } from './path'
import { DashboardContainer } from './components'

export default function Dashboard () {
  return (
    <DashboardContainer>
      <Routes>
        <Route index element={<Main />} />
        <Route path={TRADING} element={<Trading />}>
          <Route path=':agentId' element={<Trading />} />
          <Route path=':agentId/:botId' element={<Trading />} />
        </Route>
        <Route path={TRADING_VIEW} element={<TradingView />} />
        <Route path={CONFIG} element={<Config />} />
      </Routes>
    </DashboardContainer>
  )
}
