import * as React from 'react'
import { Link } from 'react-router-dom'
import { CONFIG, DASHBOARD, TRADING, TRADING_VIEW } from '../path'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import SettingsIcon from '@mui/icons-material/Settings'

const MENUS = [
  { to: DASHBOARD, text: 'Dashboard', icon: <DashboardIcon /> },
  { to: TRADING, text: 'Trading', icon: <MonetizationOnIcon /> },
  { to: TRADING_VIEW, text: 'TradingView', icon: <QueryStatsIcon /> },
  { to: CONFIG, text: 'Config', icon: <SettingsIcon /> },
]

export function MainListItems () {
  return (
    <div>{MENUS.map((props, i) => <Item key={i} {...props} />)}</div>
  )
}

function Item ({ to, text, icon }) {
  return (
    <ListItem button component={Link} to={to}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
}
