import BigNumber from 'bignumber.js'
import { toFixed } from './index'

export function getRealTimeStatus (bot, ticker, action) {
  if (!ticker || !bot) return
  if (!bot.average_price) return 'there is no position'

  const total = new BigNumber(bot.total)
  const average = new BigNumber(bot.average_price)
  const side = bot.side

  if (action === 'take-profit') {
    const bestPrice = new BigNumber(bot.take_profit_state?.best_price)
    const isEnabled = bot.take_profit?.is_enabled
    const method = bot.take_profit?.method
    const currentPrice = new BigNumber(ticker.b)
    const triggerPrice = getValue(bot.take_profit?.args?.[0], average)
    const trigger = getDescription(bot.take_profit?.args?.[0], average)
    if (!isEnabled && currentPrice.gt(triggerPrice)) {
      return `${trigger} is less than current ${currentPrice}. If enable "Take Profit", it would be executed at once.`
    }
    if (method === '1') {
      const position = getDescription(bot.take_profit?.args?.[1], total)
      if (side === 'long') {
        return `it will ask ${position}\n${currentPrice} is still less than ${trigger}`
      }
      if (side === 'short') {
        return `it will bid ${position}\n${currentPrice} is still greater than ${trigger}`
      }
    }
    if (method === '2') {
      const target = getDescription(bot.take_profit?.args?.[1], bestPrice)
      const position = getDescription(bot.take_profit?.args?.[2], total)
      if (side === 'long' && currentPrice.lte(triggerPrice)) {
        return `it will ask ${position}\n${bestPrice} is still less than trigger price ${trigger}`
      }
      if (side === 'long' && currentPrice.gt(triggerPrice)) {
        return `it will ask ${position}\nbest price ${bestPrice} is greater than trigger price ${trigger}\nbut current ${currentPrice} is still greater than target price ${target}`
      }
      if (side === 'short' && currentPrice.gte(triggerPrice)) {
        return `it will bid ${position}\n${bestPrice} is still greater than trigger price ${trigger}`
      }
      if (side === 'short' && currentPrice.lt(triggerPrice)) {
        return `it will bid ${position}\nbest price ${bestPrice} is less than trigger price ${trigger}\nbut current ${currentPrice} is still less than target price ${target}`
      }
    }
  }
  if (action === 'stop-loss') {
    const isEnabled = bot.stop_loss?.is_enabled
    const method = bot.stop_loss?.method
    const currentPrice = new BigNumber(ticker.a)
    const triggerPrice = getValue(bot.stop_loss?.args?.[0], average)
    const trigger = getDescription(bot.stop_loss?.args?.[0], average)
    if (!isEnabled && currentPrice.lt(triggerPrice)) {
      return `${trigger} is greater than current ${currentPrice}. If enable "Stop Loss", it would be executed at once.`
    }
    if (method === '1') {
      if (side === 'long') {
        return `current ${currentPrice} is still greater than ${trigger}`
      }
      if (side === 'short') {
        return `current ${currentPrice} is still less than ${trigger}`
      }
    }
  }
  return 'unexpected input'
}

function getDescription (number, base) {
  const [t, n] = [number?.[0], new BigNumber(number?.substring(1))]
  if (n.isNaN()) return undefined
  if (t === '+') return `${toFixed(base.plus(n), 6)} (${toFixed(base, 6)}${n.gte(0) ? '+' : ''}${n})`
  if (t === '%') return `${toFixed(base.times(n).abs(), 6)} (${toFixed(base.abs(), 6)}*${n})`
  return toFixed(n, 6)
}

function getValue (number, base) {
  const [t, n] = [number?.[0], new BigNumber(number?.substring(1))]
  if (n.isNaN()) return undefined
  if (t === '+') return toFixed(base.plus(n), 6)
  if (t === '%') return toFixed(base.times(n).abs(), 6)
  return toFixed(n, 6)
}
