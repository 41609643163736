export const HOME = '/'
export const DASHBOARD = '/dashboard'
export const REPORT = '/report'

// react-router-dom@6 supports relative routes now
export const SIGNIN = 'signin'
export const SIGNUP = 'signup'
export const AGENTS = 'agents'
export const CREATE_AGENT = 'agent/new'
export const BOTS = 'bots'
export const CREATE_BOT = 'bot/new'
export const BOT = 'bot'
export const TRADING = 'trading'
export const TRADING_VIEW = 'trading-view'
export const CONFIG = 'config'
