import http from './http'

const report = {
  getTransactions,
}
export default report

export async function getTransactions (id, params) {
  const query = {
    agent_id: id,
    begin: params.begin,
    end: params.end,
    order: params.order,
  }
  if (params.coin) query.coin = params.coin
  return await http.get('transaction-details', { query })
}
