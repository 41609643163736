import React, { useEffect, useState } from 'react'
import { datafeed } from './datafeed'
import { widget as WidgetConstructor } from '../../charting_library'
import { Autocomplete, Box, TextField, InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { getMarkets } from '../../api/asset'

const container = 'chart-container'
const intervals = [
  { value: '15', label: '15m' },
  { value: '60', label: '1h' },
  { value: '240', label: '4h' },
  { value: '1D', label: '1d' },
  { value: '1W', label: '1w' },
]
let tvWidget

export function TradingView () {
  const [market, setMarket] = useState()
  const [supportedMarkets, setSupportedMarkets] = useState()
  const [interval, setInterval] = useState(intervals[0].value)
  const [start, setStart] = useState(Date.now() - 24 * 60 * 60 * 1000)
  const [end, setEnd] = useState(Date.now())

  useEffect(() => {
    getMarkets().then(res => {
      if (res.body.length > 0) {
        setSupportedMarkets(res.body.map(v => v.name.toUpperCase()))
        setMarket(res.body[0].name.toUpperCase())
      }
    })
  }, [])

  useEffect(() => {
    if (market === undefined) return
    const widgetOptions = {
      symbol: market,
      interval,
      container,
      datafeed,
      library_path: '/charting_library/',
      timezone: 'Asia/Taipei',
      enabled_features: ['study_templates'],
      autosize: true,
      theme: 'Dark',
    }
    tvWidget = new WidgetConstructor(widgetOptions)

    tvWidget.onChartReady(() => {
      tvWidget.activeChart().setVisibleRange({ from: new Date(start).getTime() / 1000, to: new Date(end).getTime() / 1000 })
      tvWidget.chart().createStudy('Moving Average Triple', true, false, [5, 10, 20])
    })
  }, [market, start, end, interval])

  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Autocomplete
          disablePortal
          id="market"
          options={supportedMarkets ?? []}
          sx={{ width: 200 }}
          value={market}
          onChange={(event, newValue) => {
            setMarket(newValue)
          }}
          renderInput={(params) => <TextField {...params} label="Market" />}
        />
        <FormControl>
          <InputLabel id="interval-label">Interval</InputLabel>
          <Select
            labelId="interval-label"
            id="interval"
            value={interval}
            label="Interval"
            onChange={(e) => {
              setInterval(e.target.value)
            }}
          >
            {intervals.map(v => <MenuItem key={v.label} value={v.value}>{v.label}</MenuItem>)}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Start"
            value={start}
            onChange={(v) => {
              setStart(v)
            }}
            maxDateTime={end}
            ampm={false}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="End"
            value={end}
            onChange={(v) => {
              setEnd(v)
            }}
            minDateTime={start}
            ampm={false}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ height: 'calc(100vh - 184px)' }} id={container} />
    </>
  )
}
