import React from 'react'
import { Link } from 'react-router-dom'
import { DASHBOARD, REPORT } from './path'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

function Landing () {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Welcome to Trading Assistant
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Link to={DASHBOARD}>Dashboard</Link>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Link to={REPORT}>Report</Link>
        </Box>
      </Box>
    </Container>
  )
}

export default Landing
